import React from 'react';
import { Button } from '@components/Button';
import { TextLink } from '@components/TextLink';

type NavControlProps = {
  showBackButton: boolean;
  backText: string;
  submitText: string;
  formState: { isSubmitting: boolean; isSubmitSuccessful: boolean };
  onBack: () => void;
  onSubmit: () => Promise<void>;
};

export const NavControls = ({
  showBackButton,
  backText,
  onBack,
  submitText,
  onSubmit,
  formState: { isSubmitting, isSubmitSuccessful }
}: NavControlProps) => {
  return (
    <div className="flex justify-between items-center">
      <div className={showBackButton ? '' : 'invisible'}>
        <TextLink variant="secondary" onPress={onBack}>
          {backText}
        </TextLink>
      </div>

      <Button onPress={onSubmit} disabled={isSubmitting || isSubmitSuccessful}>
        {submitText}
      </Button>
    </div>
  );
};
