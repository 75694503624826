import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import { Else, If, Then } from '@common/components/If';
import { PageHeadingWithUserPackage } from '@common/componentsV2/PageHeadingWithUserPackage';
import { SupportLevel } from '@seek/je-shared-data/lib/types/brand';
import { Box } from '@components/Box';
import { Card, CardContent } from '@components/Card';
import Div from '../../../common/components/Div/Div';
import ZeroJobsIllustration from '../../../common/components/Illustrations/ZeroJobsIllustration';
import ImageWithTitle from '../../../common/components/ImageWithTitle/ImageWithTitle';
import { JobPostingNotAvailable } from '../../../common/componentsV2/JobPostingNotAvailable';
import ConnectedErrorMessage from '../../components/ConnectedErrorMessage/ConnectedErrorMessage';
import ListWithLoadingState from '../../components/ListWithLoadingState/ListWithLoadingState';
import { RedirectUnauthenticatedUser } from '../../components/RedirectUnauthenticatedUser';
import Submit from '../../components/Submit/Submit';
import REQUEST_KEY from '../../constants/requestKeys';
import { shouldShowApplicationCount } from '../../helpers/jobs';
import { isBackOrForwardNavigation } from '../../helpers/navigation';
import * as applicationActions from '../../store/entities/applications/actions';
import * as businessActions from '../../store/entities/businesses/actions';
import * as jobActions from '../../store/entities/jobs/actions';
import * as userDataStore from '../../store/userData/actions';
import {
  JobsDbPostJobAction,
  JobsDbRetirementCalloutText,
  PageHeadingLayout,
  PostJobAction,
  UpgradeBanner
} from './components';
import { JobCard } from './components/JobCard';
import { ListJobSkeleton } from './components/ListJobSkeleton';
import { PartnerPromo } from './components/PartnerPromo';
import { PushPermissionModal } from './components/PushPermissionModal';

@connect(
  (state) => {
    const brand = state.localisation.brandConfig.name;
    const siteCountryCode = state.localisation.countryCode;

    return {
      countryCode: state.localisation.countryCode,
      supportTier: state.localisation.supportTier,
      brandNameText: state.localisation.brandNameText,
      isAuthenticated: state.auth.isAuthenticated,
      jobs: state.entities.jobs.dashboard.list,
      nextKey: state.entities.jobs.dashboard.nextKey,
      applicationsStatus: state.entities.applications.applicationsStatus.items,
      deleteJobError: state.app.error[REQUEST_KEY.JOB.DELETE],
      businesses: state.entities.businesses.list.businesses,
      loadStack: state.app.loadStack,
      shouldLimitJobRoles:
        state.config.featureToggles.limitJobRoles[brand]?.[siteCountryCode]
    };
  },
  {
    loadApplicationsCount: applicationActions.loadCount,
    deleteJob: jobActions.deleteJob,
    pushRoute: push,
    loadDashboardJobs: jobActions.loadDashboardJobs,
    setUserData: userDataStore.set
  }
)
@withTranslation()
export default class Dashboard extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    countryCode: PropTypes.string.isRequired,
    applicationsStatus: PropTypes.object.isRequired,
    deleteJob: PropTypes.func.isRequired,
    pushRoute: PropTypes.func.isRequired,
    jobs: PropTypes.arrayOf(PropTypes.object),
    nextKey: PropTypes.string,
    loadApplicationsCount: PropTypes.func,
    loadDashboardJobs: PropTypes.func,
    deleteJobError: PropTypes.any,
    isAuthenticated: PropTypes.bool.isRequired,
    businesses: PropTypes.arrayOf(PropTypes.object),
    location: PropTypes.object,
    setUserData: PropTypes.object,
    supportTier: PropTypes.number.isRequired,
    loadStack: PropTypes.array.isRequired,
    shouldLimitJobRoles: PropTypes.bool.isRequired,
    brandNameText: PropTypes.string.isRequired
  };

  static async fetchData({ store }) {
    const hasJobs = store.getState().entities.jobs.dashboard.list.length;
    if (isBackOrForwardNavigation() && hasJobs) {
      // Skip reloading data so user returns to their previous scroll position
      // in the jobs list
      return;
    }

    if (store.getState().auth.isAuthenticated) {
      store.dispatch(jobActions.clearDashboardJobs());
      await Promise.all([
        store.dispatch(jobActions.loadDashboardJobs()),
        store.dispatch(businessActions.loadBusinesses())
      ]);
    }
  }

  componentDidMount() {
    const {
      isAuthenticated,
      location: { search },
      setUserData
    } = this.props;
    if (isAuthenticated) {
      this.requestApplicationCounts();
    }
    if (search) {
      setUserData('source.query', queryString.parse(search));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.jobs !== this.props.jobs) {
      this.requestApplicationCounts();
    }
  }

  requestApplicationCounts() {
    const { loadApplicationsCount, jobs } = this.props;

    const jobsThatArentPending = (jobs || []).filter(
      shouldShowApplicationCount
    );
    loadApplicationsCount(jobsThatArentPending.map((job) => job.id));
  }

  handleShowMoreJobsClick() {
    this.props.loadDashboardJobs(this.props.nextKey);
  }

  renderJobsList() {
    const { jobs, applicationsStatus, businesses, brandNameText, t } =
      this.props;

    // TODO: cleanup after jobsDB shutdown
    const isJobsDb = brandNameText?.includes('jobsDB');

    return (
      <>
        <PushPermissionModal />

        <PageHeadingLayout>
          <Box marginTop="xsmall" tone="transparent">
            <PageHeadingWithUserPackage heading={t('dashboardPage.heading')} />
          </Box>
          {/* // TODO: cleanup after jobsDB shutdown */}
          {isJobsDb ? <JobsDbPostJobAction /> : <PostJobAction />}
        </PageHeadingLayout>

        {/* // TODO: cleanup after jobsDB shutdown */}
        {isJobsDb ? <JobsDbRetirementCalloutText /> : null}

        <UpgradeBanner pageTitle={t('dashboardPage.title')} />

        {jobs.map((job) => {
          const business =
            (businesses || []).find(
              (business) => business.id === job.businessId
            ) || {};
          return (
            <Box key={job.id} marginTop={job !== jobs[0] ? 'medium' : 'none'}>
              <JobCard
                applicationInformation={applicationsStatus?.[job.id]}
                job={job}
                businessSize={business.businessSize}
              />
            </Box>
          );
        })}
      </>
    );
  }

  renderZeroState() {
    const { t } = this.props;

    return (
      <>
        <PageHeadingLayout>
          <Box marginTop="xsmall" tone="transparent">
            <PageHeadingWithUserPackage heading={t('dashboardPage.heading')} />
          </Box>
          <PostJobAction />
        </PageHeadingLayout>

        <UpgradeBanner pageTitle={t('dashboardPage.title')} />

        <Card>
          <CardContent>
            <ImageWithTitle
              svgComponent={ZeroJobsIllustration}
              mediumIcon
              title={t('dashboardPage.description.itsEmptyHere')}
            />
          </CardContent>
        </Card>
      </>
    );
  }

  checkLoadStatus() {
    const { loadStack } = this.props;
    const requestKeys = [REQUEST_KEY.JOB.LIST];

    return requestKeys.some((key) => loadStack.includes(key));
  }

  render() {
    const { t, countryCode, jobs, nextKey, supportTier } = this.props;
    const hasJobs = Array.isArray(jobs) && jobs.length;
    const isLoadedAndNoJobs = !this.checkLoadStatus() && !hasJobs;

    return (
      <If condition={isLoadedAndNoJobs && supportTier === SupportLevel.TIER_3}>
        <Then>
          <JobPostingNotAvailable />
        </Then>
        <Else>
          <Div marginBottom="large">
            <RedirectUnauthenticatedUser to={`/${countryCode}/login`} />
            <Helmet title={t('dashboardPage.title')} />

            <ConnectedErrorMessage
              requestKey={REQUEST_KEY.JOB.LIST}
              withCard
              extraTopMargin
            >
              <ListWithLoadingState
                requestKeys={[REQUEST_KEY.JOB.LIST]}
                loadingState={
                  <ListJobSkeleton count={2} showButtonSkeleton={!nextKey} />
                }
                zeroState={this.renderZeroState()}
                isRetainList={!!nextKey}
                isZeroState={!hasJobs}
              >
                {this.renderJobsList()}
              </ListWithLoadingState>
            </ConnectedErrorMessage>

            {nextKey && (
              <Submit
                data-test-key="show-more-jobs-button"
                onClick={this.handleShowMoreJobsClick.bind(this)}
              >
                {t('dashboardPage.action.showMoreJobs')}
              </Submit>
            )}

            {!!hasJobs && <PartnerPromo testID={'partner-promo-banner'} />}
          </Div>
        </Else>
      </If>
    );
  }
}
