import type { Step } from '@common/hooks/multiStep';

interface FindParams {
  allSteps: Step[];
  currentStepIndex: number;
  data: Record<string, unknown>;
  validator: any;
}
export async function findFirstPreviousInvalidStep({
  allSteps,
  currentStepIndex,
  data,
  validator
}: FindParams): Promise<string | null> {
  const previousSteps = allSteps.slice(0, currentStepIndex);
  console.info('Checking previous steps', previousSteps);
  const validationResults = await Promise.all(
    previousSteps.map(async (step) => {
      const valid = await validator(step.rules, data);
      console.info('Validation result', { stepId: step.id, valid });
      return {
        stepId: step.id,
        valid
      };
    })
  );
  return validationResults.find((result) => !result.valid)?.stepId ?? null;
}
