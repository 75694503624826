import React from 'react';
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';
import { useTranslation } from '@common/hooks/useTranslation';
import { SupportedCurrencies } from '@seek/je-shared-data/lib/types/brand';
import { SalaryRate } from '@seek/je-shared-data/lib/types/jobs';
import { SalaryWithOptionalAmount } from '../../MultiStepPostJob/types';
import { expectedSalaryRules } from '../../rules';
import { ExpectedSalaryField } from './ExpectedSalaryField';

export default function ExpectedSalary<T extends FieldValues>({
  name,
  control
}: UseControllerProps<T>) {
  const { t } = useTranslation();

  return (
    <Controller
      name={name}
      control={control}
      rules={expectedSalaryRules(t)}
      render={({ field: { onChange, onBlur, value }, formState }) => (
        <ExpectedSalaryField
          onChange={onChange}
          onBlur={onBlur}
          value={ensureValue(value)}
          error={formState?.errors[name]?.message as string}
        />
      )}
    />
  );
}

function ensureValue(
  value: SalaryWithOptionalAmount | undefined
): SalaryWithOptionalAmount {
  return (
    value || {
      type: 'exact',
      amount: undefined,
      currency: SupportedCurrencies.AUD, // TODO get from country
      rate: SalaryRate.MONTHLY
    }
  );
}
