import queryString from 'query-string';
import { useCreateJob } from '@common/hooks/job';

export function useSaveJob() {
  const { createJobAsync, error } = useCreateJob();

  return {
    saveJob: async (repostJobId: string, sourceParams: Record<string, any>) =>
      await createJobAsync({
        repostFromJobId: repostJobId,
        sourceParams: queryString.stringify(sourceParams)
      }),
    error
  };
}
