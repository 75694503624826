import { useMemo } from 'react';
import { useDefaultBusiness } from '@common/components/Caja/hooks';
import { PostJobFormData } from '@common/components/Job/forms/MultiStepPostJob';
import { DraftJob } from '@common/hooks/job/useFindRecentDraft';
import { BusinessWithUsers } from '@store/entities/businesses/types';
import { toPostJobFormData } from '../transformers/draft/toPostJobFormData';

export const useMapDraftToFormData = (
  draft: DraftJob,
  businesses: BusinessWithUsers[]
): PostJobFormData => {
  const defaultBusiness = useDefaultBusiness(businesses);
  const draftBusiness = findDraftBusiness(businesses, draft?.businessId);
  const business = draftBusiness ?? defaultBusiness;

  return useMemo(() => toPostJobFormData(draft, business), [draft, business]);
};

const findDraftBusiness = (
  businesses: BusinessWithUsers[],
  draftBusinessId?: string
) => {
  if (!draftBusinessId) return;

  return businesses.find((business) => business.id === draftBusinessId);
};
