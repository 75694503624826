import React, { ReactNode, createContext, useContext } from 'react';
import { ApplicationFilterValues, FILTERS } from '../../types';
import {
  DEFAULT_FILTER_STATE,
  FilterForm,
  FilterValuesKey,
  FilterValuesProperty,
  useApplySelectedFilters,
  useFilterForm
} from './hooks';

type FilterFormContextProps = {
  children: ReactNode;
  setApplicationFilters: (filter: ApplicationFilterValues) => void;
  applicationFilters: ApplicationFilterValues;
};

/**
 * Context type for the FilterForm.
 *
 * @typedef {Object} FilterFormContextType
 * @property {FilterForm} filterForm - The current state of the filter form.
 * @property {ApplicationFilterValues} applicationFilters - The current application filter values.
 * @property {React.Dispatch<React.SetStateAction<FilterForm>>} setFilterForm - Function to update the filter form state.
 * @property {function(key: FilterValuesKey): function(value: FilterValuesProperty): void} handleFieldChange - Function to handle changes to a specific filter field.
 * @property {function(key: FILTERS): function(): void} handleFieldClear - Function to clear a specific filter field.
 * @property {function(): void} applySelectedFilters - Function to apply the selected filters.
 * @property {function(): void} clearAllFilters - Function to clear all filters.
 */
type FilterFormContextType = {
  filterForm: FilterForm;
  applicationFilters: ApplicationFilterValues;
  setFilterForm: React.Dispatch<React.SetStateAction<FilterForm>>;
  handleFieldChange: (
    key: FilterValuesKey
  ) => (value: FilterValuesProperty) => void;
  handleFieldClear: (key: FILTERS) => () => void;
  applySelectedFilters: () => void;
  clearAllFilters: () => void;
};

const FilterFormContext = createContext<FilterFormContextType>({
  filterForm: DEFAULT_FILTER_STATE,
  applicationFilters: DEFAULT_FILTER_STATE,
  setFilterForm: () => {},
  handleFieldChange: () => () => {},
  handleFieldClear: () => () => {},
  applySelectedFilters: () => {},
  clearAllFilters: () => {}
});

export const FilterFormProvider: React.FC<FilterFormContextProps> = ({
  children,
  setApplicationFilters,
  applicationFilters
}) => {
  const { filterForm, setFilterForm, handleFieldChange, handleFieldClear } =
    useFilterForm();

  const { applySelectedFilters, clearAllFilters } = useApplySelectedFilters({
    filterForm,
    setApplicationFilters,
    setFilterForm
  });

  return (
    <FilterFormContext.Provider
      value={{
        filterForm,
        applicationFilters,
        setFilterForm,
        handleFieldChange,
        handleFieldClear,
        applySelectedFilters,
        clearAllFilters
      }}
    >
      {children}
    </FilterFormContext.Provider>
  );
};

export const useFilterFormContext = (): FilterFormContextType => {
  const context = useContext(FilterFormContext);
  if (!context) {
    throw new Error(
      'useFilterFormContext must be used within a FilterFormProvider'
    );
  }
  return context;
};
