import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Business, JobTitle } from '../../../fields';
import { MultiStepPostJobContext } from '../../MultiStepPostJobContext';
import { NavControls } from '../../components';
import { TitleAndBusinessFormData } from './types';

type TitleAndBusinessFormProps = {
  defaultValues: TitleAndBusinessFormData;
  stepInfo: {
    showBackButton: boolean;
    backText: string;
    submitText: string;
  };
  onSubmit: (data: TitleAndBusinessFormData) => void;
  onBack: () => void;
};

export const TitleAndBusinessForm = ({
  defaultValues,
  stepInfo,
  onSubmit,
  onBack
}: TitleAndBusinessFormProps) => {
  const { handleSubmit, control, formState } =
    useForm<TitleAndBusinessFormData>({
      defaultValues
    });

  const { isBusinessEditable, redirectCreateBusinessTo } = useContext(
    MultiStepPostJobContext
  );

  const handleFormSubmitForStep = async (data: TitleAndBusinessFormData) => {
    await onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmitForStep)}>
      <div className="space-y-12">
        <JobTitle name="jobTitle" control={control} />

        <Business
          name="business"
          control={control}
          isBusinessEditable={isBusinessEditable}
          shouldRedirectBusinessCreatedTo={redirectCreateBusinessTo}
        />

        <NavControls
          onBack={onBack}
          onSubmit={handleSubmit(handleFormSubmitForStep)}
          formState={formState}
          {...stepInfo}
        />
      </div>
    </form>
  );
};
