import { PostJobFormData } from '@common/componentsV2/Job/forms/MultiStepPostJob';
import {
  businessRules,
  expectedSalaryRules,
  immediateStartRules,
  jobTitleRules,
  jobTypeRules,
  rightToWorkRules,
  shiftAvailabilityRules,
  workExperienceRules
} from '@common/componentsV2/Job/forms/rules';
import { Step } from '@common/hooks/multiStep/types';

export const getSteps = (_data: PostJobFormData): Step[] => {
  return [
    {
      id: 'title',
      path: '/job-title-business-selection',
      title: 'postJobTitleStep.stepTitle',
      pageTitle: 'editJobPage.heading',
      rules: { business: businessRules, jobTitle: jobTitleRules }
    },
    {
      id: 'experience',
      path: '/work-experience-salary',
      title: 'postJobExperienceStep.stepTitle',
      pageTitle: 'editJobPage.heading',
      rules: {
        workExperience: workExperienceRules,
        salary: expectedSalaryRules
      }
    },
    {
      id: 'jobType',
      path: '/job-type-shift-availability',
      title: 'postJobTypeStep.stepTitle',
      pageTitle: 'editJobPage.heading',
      rules: {
        jobType: jobTypeRules,
        shiftAvailability: shiftAvailabilityRules
      }
    },
    {
      id: 'additionalInfo',
      path: '/right-to-work-start-date',
      title: 'postJobAdditionalInfoStep.stepTitle',
      pageTitle: 'editJobPage.heading',
      rules: {
        rightToWork: rightToWorkRules,
        immediateStart: immediateStartRules
      }
    },
    {
      id: 'jobDescription',
      path: '/review-job-description',
      title: 'postJobDescriptionStep.stepTitle',
      pageTitle: 'editJobPage.heading',
      rules: {} // Don't use because we know the job description is optional
    }
  ];
};
