import React from 'react';
import { Spinner } from '@common/components/Spinner';
import { ClientError } from '@common/helpers/errors';
import { StepError, StepWrapper } from '../../components';
import { PostJobFormData } from '../../types';
import { ExperienceAndSalaryForm } from './ExperienceAndSalaryForm';
import { ExperienceAndSalaryFormData } from './types';

type ExperienceAndSalaryStepProps = {
  isLoading: boolean;
  error?: ClientError;
  formData: ExperienceAndSalaryFormData;
  stepInfo: {
    showBackButton: boolean;
    backText: string;
    submitText: string;
  };
  onSubmit: (data: PostJobFormData) => Promise<void>;
  onBack: () => void;
};

export const ExperienceAndSalaryStep = ({
  isLoading,
  error,
  formData,
  onSubmit,
  onBack,
  stepInfo
}: ExperienceAndSalaryStepProps) => {
  if (isLoading) {
    return (
      <StepWrapper>
        <Spinner size="50" />
      </StepWrapper>
    );
  }

  return (
    <StepWrapper>
      <ExperienceAndSalaryForm
        defaultValues={formData}
        onSubmit={onSubmit}
        onBack={onBack}
        stepInfo={stepInfo}
      />

      <StepError error={error} />
    </StepWrapper>
  );
};
