import { useHistory, useParams, useRouteMatch } from 'react-router-dom';

/**
 * Replaces the last part of a given path with a new part and substitutes the country code placeholder.
 *
 * @param path - The original matching route path defined in the route configuration. i.e. '/:countryCode/page/*'.
 * @param newPart - The new part to replace the last part of the path.
 * @param countryCode - The country code to replace the ':countryCode' placeholder in the path.
 * @returns The modified path with the last part replaced and the country code substituted.
 */
function replaceLastPathPart({
  path,
  newPart,
  prevPart
}: {
  path: string;
  newPart: string;
  prevPart?: string;
}): string {
  if (prevPart) return path.replace('/*', newPart);
  return `${path}${newPart}`;
}

export function useRedirectToNewPath() {
  const history = useHistory();
  const { path } = useRouteMatch();
  const pathParams = useParams();

  const redirectToNewPath = (newPart: string) => {
    const prevPart = pathParams[0];
    let newPath = replaceLastPathPart({ path, newPart, prevPart });

    if (pathParams) {
      const paramKeys = Object.keys(pathParams);
      paramKeys.forEach((paramKey) => {
        newPath = newPath.replace(`:${paramKey}`, pathParams[paramKey]);
      });
    }

    history.push(newPath);
  };

  return { redirectToNewPath };
}
