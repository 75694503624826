import React from 'react';
import { Card, CardContent } from '@components/Card';

type StepWrapperProps = {
  children: React.ReactNode;
};

export const StepWrapper = ({ children }: StepWrapperProps) => {
  return (
    <Card>
      <CardContent>{children}</CardContent>
    </Card>
  );
};
