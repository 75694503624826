import React from 'react';
import { Redirect } from '@common/components/Redirect';
import { useGetJobEditedSuccessLink } from '@common/hooks/appPageLinks';

type Props = {
  jobId: string;
};

export function EditJobSuccess({ jobId }: Props) {
  const editJobSuccessLink = useGetJobEditedSuccessLink(jobId);

  return <Redirect to={editJobSuccessLink} />;
}
