import React from 'react';
import { useTranslation } from 'react-i18next';
import { BusinessImageAndDetails } from '@common/components/BusinessImageAndDetails';
import { RedirectTo } from '@common/search';
import { BusinessWithUsers } from '@store/entities/businesses/types';
import { Box } from '@components/Box';
import { Link } from '@components/Link';
import { useModal } from '@components/Modal';
import { Row } from '@components/Row';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';
import { BusinessName } from './components/BusinessName/BusinessName';
import { ChangeBusinessModal } from './components/ChangeBusinessModal';
import { useBusinessInformation } from './hooks/useBusinessInformation';
import { PostJobFormBusiness } from './types';

export type EditableBusinessInformationProps = {
  selectedBusiness: PostJobFormBusiness;
  shouldRedirectBusinessCreatedTo: RedirectTo;
  onBusinessSelected: (business: BusinessWithUsers) => void;
  disableAddBusiness: boolean;
};

export const EditableBusinessInformation = ({
  shouldRedirectBusinessCreatedTo,
  onBusinessSelected,
  selectedBusiness,
  disableAddBusiness
}: EditableBusinessInformationProps) => {
  const { t } = useTranslation();

  const { locationText } = useBusinessInformation({
    business: selectedBusiness
  });

  const { isModalOpen, openModal, closeModal } = useModal();

  return (
    <Stack spacing="medium">
      <BusinessImageAndDetails businessSize={selectedBusiness.businessSize}>
        <Row spacing="medium">
          <Box flexShrink={1}>
            <BusinessName business={selectedBusiness} />
          </Box>
          <Link onPress={openModal} testID="business-information-change-link">
            {t('common.action.change')}
          </Link>
        </Row>

        <Text>{locationText}</Text>
      </BusinessImageAndDetails>

      <ChangeBusinessModal
        shouldRedirectBusinessCreatedTo={shouldRedirectBusinessCreatedTo}
        selectedBusinessId={selectedBusiness.id}
        isBusinessModalOpen={isModalOpen}
        onRequestClose={closeModal}
        onBusinessSelected={onBusinessSelected}
        disableAddBusiness={disableAddBusiness}
      />
    </Stack>
  );
};
