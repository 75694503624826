import React from 'react';
import { useForm } from 'react-hook-form';
import { JobType, ShiftAvailability } from '../../../fields';
import { NavControls } from '../../components';
import { PostJobFormData } from '../../types';
import { JobTypeAndShiftFormData } from './types';

type JobTypeAndShiftFormProps = {
  defaultValues: JobTypeAndShiftFormData;
  stepInfo: {
    showBackButton: boolean;
    backText: string;
    submitText: string;
  };
  onSubmit: (data: PostJobFormData) => Promise<void>;
  onBack: () => void;
};

export const JobTypeAndShiftForm = ({
  defaultValues,
  stepInfo,
  onSubmit,
  onBack
}: JobTypeAndShiftFormProps) => {
  const { handleSubmit, control, formState } = useForm<JobTypeAndShiftFormData>(
    {
      defaultValues
    }
  );

  const handleFormSubmitForStep = async (data: JobTypeAndShiftFormData) => {
    await onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmitForStep)}>
      <div className="space-y-12">
        <JobType name="jobType" control={control} />
        <ShiftAvailability name="shiftAvailability" control={control} />

        <NavControls
          onBack={onBack}
          onSubmit={handleSubmit(handleFormSubmitForStep)}
          formState={formState}
          {...stepInfo}
        />
      </div>
    </form>
  );
};
