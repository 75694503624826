import React from 'react';
import { useTranslation } from '@common/hooks/useTranslation';
import { ProgressIndicator } from '@components/ProgressIndicator';
import { Stack } from '@components/Stack';

export type StepProgressIndicatorProps = {
  stepTitle: string;
  currentStepIndex: number;
  totalSteps: number;
};

export const StepProgressIndicator = ({
  stepTitle,
  currentStepIndex,
  totalSteps
}: StepProgressIndicatorProps) => {
  const { t } = useTranslation();
  const currentStepNumber = currentStepIndex + 1;
  return (
    <Stack
      paddingLeft="medium"
      paddingRight="medium"
      paddingBottom="medium"
      tone="transparent"
    >
      <h2 className="mb-2 font-normal">
        {t(stepTitle, { stepNumber: currentStepNumber })}
      </h2>
      {/* Pass current and max to progress indicator component */}
      <ProgressIndicator value={currentStepNumber} max={totalSteps + 1} />
    </Stack>
  );
};
