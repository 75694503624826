import { getQueryKey } from '@trpc/react-query';
import { trpcBff } from '@trpcBff';
import { Params } from './useLoadJobForRepost';

export const loadJobForReposeKey = ({ repostJobId }: Params) => {
  return getQueryKey(
    trpcBff.jobs.drafts.loadJobForRepost,
    {
      repostJobId
    },
    'query'
  );
};
