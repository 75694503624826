import { useCallback, useState } from 'react';
import { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';

export function useBusinessState() {
  const [businessCountryCode, set] = useState<SupportedCountryCode | null>();

  const setCountryCode = useCallback(
    (countryCode?: string) => {
      if (!countryCode) return;
      if (formatted(countryCode) === businessCountryCode) return;

      set(formatted<SupportedCountryCode>(countryCode));
    },
    [set, businessCountryCode]
  );

  return [businessCountryCode, setCountryCode] as const;
}

const formatted = <T>(str: string) => str.toLowerCase() as T;
