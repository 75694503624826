import React from 'react';
import { T, useTranslation } from '@common/hooks/useTranslation';
import {
  SHIFT_AVAILABILITY_TYPE,
  ShiftAvailability,
  ShiftAvailabilityType
} from '@seek/je-shared-data/lib/types/jobs';
import { Column } from '@components/Column';
import { Text } from '@components/Text';

type ShiftAvailabilityOptions = {
  key: ShiftAvailabilityType;
  label: React.ReactElement;
};

const shiftAvailabilityOptions = (t: T): ShiftAvailabilityOptions[] => [
  {
    key: SHIFT_AVAILABILITY_TYPE.ANY_TIME,
    label: (
      <Column>
        <Text>
          {t('postJobForm.shiftAvailability.availabilityTypes.anyTime.label')}
        </Text>

        <Text tone="neutralLight" size="small">
          {t(
            'postJobForm.shiftAvailability.availabilityTypes.anyTime.description'
          )}
        </Text>
      </Column>
    )
  },
  {
    key: SHIFT_AVAILABILITY_TYPE.SPECIFIC,
    label: (
      <Column>
        <Text>
          {t(
            'postJobForm.shiftAvailability.availabilityTypes.specificTimes.label'
          )}
        </Text>

        <Text tone="neutralLight" size="small">
          {t(
            'postJobForm.shiftAvailability.availabilityTypes.specificTimes.description'
          )}
        </Text>
      </Column>
    )
  }
];

export function useShiftAvailabilityTypeRadio(
  value: ShiftAvailability | undefined,
  onChange: (value: ShiftAvailability) => void
) {
  const { t } = useTranslation();

  const onShiftAvailabilityTypeChange = (shiftAvailabilityType: string) => {
    onChange({
      ...value,
      type: shiftAvailabilityType as ShiftAvailabilityType
    });
  };

  return {
    shiftAvailabilityType: value?.type,
    shiftAvailabilityTypeOptions: shiftAvailabilityOptions(t),
    onShiftAvailabilityTypeChange
  };
}
