import React from 'react';
import { useCreateJob } from '@common/hooks/job';
import { useUpdateDraft } from '@common/hooks/job/useUpdateDraft';
import {
  JOB_TYPES,
  SHIFT_AVAILABILITY_TYPE,
  WorkExperience
} from '@seek/je-shared-data/lib/types/jobs';
import { Button } from '@components/Button';
import { Heading } from '@components/Heading';
import { Stack } from '@components/Stack';

export const PostJob = () => {
  const params = {
    draft: {
      businessId: 'd86848df-b090-4d25-8b51-08bd79474490',
      title: 'New job created from draft 2',
      description:
        '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor. Cras elementum ultrices diam. Maecenas ligula massa, varius a, semper congue, euismod non, mi.</p>',
      immediateStart: false,
      shiftAvailability: {
        type: SHIFT_AVAILABILITY_TYPE.ANY_TIME
      },
      type: JOB_TYPES.FULL_TIME,
      role: 'baker',
      workExperience: WorkExperience.MORE_THAN_FOUR_YEARS_EXPERIENCE,
      rightToWork: false
    }
  };

  const { updateDraftAsync } = useUpdateDraft();
  const { createJobAsync } = useCreateJob();

  const onDraftCreatePress = async () => await updateDraftAsync(params);

  const onJobCreatePress = async () => await createJobAsync({});

  return (
    <Stack padding="medium" spacing="medium">
      <Heading level="h2">{'Job title: "' + params.draft.title + '"'}</Heading>
      <Button onPress={onDraftCreatePress}>Create Job Draft</Button>
      <Button variant="secondary" onPress={onJobCreatePress}>
        Create Job
      </Button>
    </Stack>
  );
};
