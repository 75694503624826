import React, { useEffect, useRef } from 'react';
import queryString from 'query-string';
import {
  MultiStepPostJob,
  PostJobFormData
} from '@common/components/Job/forms/MultiStepPostJob';
import {
  trackMaxLengthValidationError,
  trackMinLengthValidationError
} from '@common/components/Job/forms/fields/JobDescription/jobDescriptionActions';
import {
  JOB_DESCRIPTION_MAX_LENGTH,
  JOB_DESCRIPTION_MULTISTEP_MIN_LENGTH
} from '@common/constants/validation';
import { useLoadBusinesses } from '@common/hooks/business';
import { useCreateJob } from '@common/hooks/job';
import { useFindRecentDraft } from '@common/hooks/job/useFindRecentDraft';
import { useUpdateDraft } from '@common/hooks/job/useUpdateDraft';
import { useRedirectUnauthenticatedUser } from '@common/hooks/useRedirectUnauthenticatedUser';
import { useGetSourceParams } from '@common/hooks/useSearchQueryParam';
import { useAppDispatch } from '@store/hooks';
import { getSteps } from './getSteps';
import { useMapDraftToFormData } from './hooks';
import { toUpdateDraftParams } from './transformers/postJobFormData/toUpdateDraftParams';

export interface InvalidDescriptionData {
  amount: number;
  requiredAmount: number;
  reason: 'MAX_LENGTH' | 'MIN_LENGTH';
}

type Props = {
  onNext: (stepData: PostJobFormData) => void;
  onCompleted: (stepData: Partial<PostJobFormData>, { jobId }) => void;
  onLoad: (stepData: PostJobFormData) => void;
};
export const PostJob = ({ onNext, onCompleted, onLoad }: Props) => {
  useRedirectUnauthenticatedUser();

  const sourceParams = useGetSourceParams();
  const dispatch = useAppDispatch();
  const { createJobAsync, error: createJobError } = useCreateJob();
  const { data: savedDraft, isLoading: draftLoading } = useFindRecentDraft();
  const { updateDraftAsync, error: updateDraftError } = useUpdateDraft();
  const { businesses, isLoading: businessesLoading } = useLoadBusinesses();
  const draftJob = useMapDraftToFormData(savedDraft, businesses);
  const isLoading = businessesLoading || draftLoading;
  const onLoadRef = useRef(onLoad);
  useEffect(() => {
    if (draftJob && !isLoading) onLoadRef.current(draftJob);
  }, [draftJob, isLoading]);

  const saveJob = async () =>
    await createJobAsync({
      sourceParams: queryString.stringify(sourceParams)
    });

  const saveDraft = async (data: PostJobFormData) =>
    await updateDraftAsync({
      draft: toUpdateDraftParams(data)
    });

  const onNextStep = async (data: PostJobFormData) => {
    onNext(data);
    await saveDraft(data);
  };

  const onPostJobCompleted = async (data: PostJobFormData) => {
    const { id: jobId } = await saveJob();
    onCompleted(data, { jobId });
  };

  const handleInvalidDescription = (data: InvalidDescriptionData) => {
    if (data.reason === 'MIN_LENGTH') {
      dispatch(trackMinLengthValidationError());
    } else if (data.reason === 'MAX_LENGTH') {
      dispatch(trackMaxLengthValidationError());
    }
  };

  return (
    <MultiStepPostJob
      getFormSteps={getSteps}
      onCompleted={onPostJobCompleted}
      isLoading={isLoading}
      jobData={draftJob}
      error={createJobError || updateDraftError}
      onNext={onNextStep}
      formOptions={{
        jobDescriptionLength: {
          min: JOB_DESCRIPTION_MULTISTEP_MIN_LENGTH,
          max: JOB_DESCRIPTION_MAX_LENGTH
        },
        onInvalidDescription: handleInvalidDescription
      }}
    />
  );
};
