import React from 'react';
import CreateAccountForm from '@common/components/AccountMultiStep/forms/createAccount/CreateAccountForm';
import { CompleteRegisterFormData } from '@common/components/AccountMultiStep/forms/createAccount/types';
import { useRegister, useSendVerificationEmail } from '@common/hooks/account';
import { useBrandName } from '@common/hooks/localisation';
import { useGetSourceParams } from '@common/hooks/useSearchQueryParam';
import { useShowAgeVerification } from '@common/hooks/useShowAgeVerification';
import { useShowCookieConsent } from '@common/hooks/useShowCookieConsent';
import { ErrorResponse } from '@store/auth/types';
import { Stack } from '@components/Stack';

export function CreateAccount({ onSubmit, submitBtnText }) {
  const sourceParams = useGetSourceParams();
  const brandName = useBrandName();
  const register = useRegister();
  const sendVerificationEmail = useSendVerificationEmail();
  const showCookieConsent = useShowCookieConsent();
  const showAgeVerification = useShowAgeVerification();

  async function onRegisterSubmit(
    data: CompleteRegisterFormData
  ): Promise<{ isSuccessful: boolean; error?: ErrorResponse }> {
    const response = await register({
      registerData: data,
      sourceParams
    });

    if (response.isSuccessful) {
      await sendVerificationEmail({ isResend: false });
    }

    return response;
  }

  async function registerFormSubmit(data: CompleteRegisterFormData) {
    const response = await onRegisterSubmit(data);

    if (response?.isSuccessful) {
      onSubmit({});
    }

    return response;
  }

  return (
    <Stack paddingTop="medium" padding="xlarge">
      <CreateAccountForm
        submitBtnText={submitBtnText}
        onSubmit={registerFormSubmit}
        brandNameText={brandName}
        showCookieConsent={showCookieConsent}
        showAgeVerification={showAgeVerification}
      />
    </Stack>
  );
}
