import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { getStepIndex } from './model';
import { FormStepProps, Step } from './types';
import { useRedirectToNewPath } from './useRedirectToNewPath';

type UseStepProps<T> = {
  data: Partial<T>;
  navOptions?: {
    backText: string;
    submitText: { lastStep: string; nextStep: string };
  };
  getSteps: (data: Partial<T>) => Step[];
  onCompleted?: (data: T) => void;
};

type UseStepsReturn<T> = {
  steps: Step[];
  currentStep: Step;
  currentStepIndex: number;
  stepInfo: FormStepProps['stepInfo'];
  gotoPreviousStep: () => void;
  gotoNextStep: (stepData: Partial<T>) => Promise<void>;
  gotoSpecificStep: (stepId: string) => void;
  stepsToStepIds: (steps: Step[]) => string[];
};

/**
 * Custom hook to manage multi-step form logic.
 *
 * @template T - The type of the data object.
 *
 * @param {Partial<T>} data - The data for the form steps.
 * @param {Object} [navOptions] - Options for the navigation buttons. This overrides the default navigation button text
 * @param {string} [navOptions.backText] - The text for the back button.
 * @param {Object} [navOptions.submitText] - The text for the submit button.
 * @param {string} [navOptions.submitText.lastStep] - The text for the submit button on the last step.
 * @param {string} [navOptions.submitText.nextStep] - The text for the submit button on all other steps.
 * @param {(data: T) => void} [onCompleted] - Callback function to handle form completion.
 * @param {(data: Partial<T>) => Step[]} getSteps - Function to get the steps based on the data.
 *
 * @returns {UseStepsReturn<T>} - The steps, current step, current step index, step info, and functions to navigate between steps.
 */
export const useSteps = <T>({
  data,
  onCompleted,
  getSteps,
  navOptions
}: UseStepProps<T>): UseStepsReturn<T> => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { redirectToNewPath } = useRedirectToNewPath();

  const { backText, submitText } = {
    backText: t('common.action.back'),
    submitText: {
      lastStep: t('common.action.submit'),
      nextStep: t('common.action.next')
    },
    ...navOptions
  };

  const steps = getSteps(data);
  const currentStepIndex = getStepIndex(steps, pathname);
  const currentStep = steps[currentStepIndex];
  const isLastStep = currentStepIndex === steps.length - 1;
  const isFirstStep = currentStepIndex === 0;

  const stepInfo: FormStepProps['stepInfo'] = {
    showBackButton: !isFirstStep,
    backText: backText,
    submitText: isLastStep ? submitText.lastStep : submitText.nextStep
  };

  const gotoPreviousStep = () => {
    redirectToNewPath(steps[currentStepIndex - 1].path);
  };

  const gotoNextStep = async (stepData: Partial<T>) => {
    const updatedData = { ...data, ...stepData };
    const updatedSteps = getSteps(updatedData);

    if (isLastStep) {
      await onCompleted?.(updatedData as T);
    } else {
      redirectToNewPath(updatedSteps[currentStepIndex + 1].path);
    }
  };

  const gotoSpecificStep = (stepId: string) => {
    const stepIndex = steps.findIndex((step) => step.id === stepId);
    if (stepIndex !== -1) {
      redirectToNewPath(steps[stepIndex].path);
    }
  };

  const stepsToStepIds = (stepData: Step[]) => stepData.map((step) => step.id);

  return {
    steps,
    currentStep,
    currentStepIndex,
    stepInfo,
    gotoPreviousStep,
    gotoNextStep,
    gotoSpecificStep,
    stepsToStepIds
  };
};
