import React from 'react';
import { JoraEmployerHelmet } from '@common/components/Helmet';
import { useTranslation } from '@common/hooks/useTranslation';
import { PageHeader } from '@components/PageHeader';
import { Stack } from '@components/Stack';
import { StepProgressIndicator } from './StepProgressIndicator/StepProgressIndicator';

export interface CajaWrapperProps {
  children: React.ReactNode;
  title: string;
  step: { title: string; index: number; total: number };
}

export default function CajaWrapper(props: CajaWrapperProps) {
  const { title, step } = props;
  const { t } = useTranslation();
  return (
    <Stack tone="transparent">
      <JoraEmployerHelmet title={t(title)} />
      <PageHeader>{t(title)}</PageHeader>

      <StepProgressIndicator
        stepTitle={step.title}
        currentStepIndex={step.index}
        totalSteps={step.total}
      />

      <div z-index={1}>{props.children}</div>
    </Stack>
  );
}
