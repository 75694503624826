import React from 'react';
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';
import { useTranslation } from '@common/hooks/useTranslation';
import { immediateStartRules } from '../../rules';
// TODO: migrate this field to common/components/Job
import { ImmediateStartField } from './ImmediateStartField';

export default function ImmediateStart<T extends FieldValues>({
  name,
  control
}: UseControllerProps<T>) {
  const { t } = useTranslation();

  return (
    <Controller
      name={name}
      control={control}
      rules={immediateStartRules(t)}
      render={({ field: { onChange, value }, formState }) => (
        <ImmediateStartField
          onChange={onChange}
          value={value}
          error={formState?.errors[name]?.message as string}
        />
      )}
    />
  );
}
