import { Response } from 'je-bff/src/jobs/drafts/loadPostedJobToDraft/loadPostedJobToDraft';
import { ClientError } from '@common/helpers/errors';
import { trpcBff } from '@trpcBff';

export type Params = {
  repostJobId: string;
  queryOpts?: {
    enabled?: boolean;
  };
};

export type DraftJob = ReturnType<typeof useLoadJobForRepost>['data'];

export type Return = {
  data: Response;
  isLoading: boolean;
  isFetched: boolean;
  isError: boolean;
  error?: ClientError;
};

export const useLoadJobForRepost = ({
  repostJobId,
  queryOpts: { enabled = true } = {}
}: Params): Return => {
  const { data, isError, isFetched, isLoading, error } =
    trpcBff.jobs.drafts.loadJobForRepost.useQuery(
      { repostJobId },
      {
        staleTime: 10000,
        retry: false,
        enabled
      }
    );

  return {
    data,
    isLoading,
    isFetched,
    isError,
    error: getClientError(error)
  };
};

const getClientError = (errorProperties) => {
  if (!errorProperties) return;

  return new ClientError('errors.defaultWithGuid', {
    guid: errorProperties.guid,
    responseBody: errorProperties.responseBody
  });
};
