import React from 'react';
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';
import { useTranslation } from '@common/hooks/useTranslation';
import { rightToWorkRules } from '../../rules';
// TODO: migrate this field to common/components/Job
import { RightToWorkRadioField } from './RightToWorkRadioField';

export default function RightToWork<T extends FieldValues>({
  name,
  control
}: UseControllerProps<T>) {
  const { t } = useTranslation();

  return (
    <Controller
      name={name}
      control={control}
      rules={rightToWorkRules(t)}
      render={({ field: { onChange, value }, formState }) => (
        <RightToWorkRadioField
          onChange={onChange}
          value={value}
          error={formState?.errors[name]?.message as string}
        />
      )}
    />
  );
}
