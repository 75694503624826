import { T } from '@common/hooks/useTranslation';

/**
 * Generates validation rules for the immediate start field.
 * @param t - The translation function.
 * @returns The validation rules object.
 */
export function immediateStartRules(t: T) {
  return {
    validate: (value: boolean) => {
      if (value === undefined) {
        return t('validations.job.immediateStart.required');
      }
    }
  };
}
