import React from 'react';
import VerifyEmailForm from '@common/components/AccountMultiStep/forms/verifyEmail/VerifyEmailForm';
import {
  CompleteVerifyFormData,
  ResponseErrors
} from '@common/components/AccountMultiStep/forms/verifyEmail/types';
import {
  useSendVerificationEmail,
  useVerifyEmail
} from '@common/hooks/account';
import { useRedirectUnauthenticatedUser } from '@common/hooks/useRedirectUnauthenticatedUser';
import { getUser } from '@store/auth';
import { useAppSelector } from '@store/hooks';
import { Stack } from '@components/Stack';

export function VerifyEmail({ onSubmit, submitBtnText }) {
  useRedirectUnauthenticatedUser();

  const { currentUser } = useAppSelector(getUser);
  const verifyEmail = useVerifyEmail();
  const sendVerificationEmail = useSendVerificationEmail();

  async function onVerifySubmit(data: CompleteVerifyFormData) {
    const { isSuccessful, error } = await verifyEmail({
      verificationCode: data.emailVerificationCode
    });
    return { isSuccessful, error };
  }

  async function verifyFormSubmit(
    data: CompleteVerifyFormData
  ): Promise<void | ResponseErrors> {
    const { isSuccessful, error } = await onVerifySubmit(data);

    if (error) {
      return { field: 'emailVerificationCode', errorMessage: error.message };
    }

    if (isSuccessful) {
      onSubmit({});
    }
  }

  async function resendVerificationEmail() {
    await sendVerificationEmail({ isResend: true });
  }

  return (
    <Stack paddingTop="medium" padding="large">
      <VerifyEmailForm
        submitBtnText={submitBtnText}
        userEmail={currentUser.email || ''}
        onResend={resendVerificationEmail}
        onSubmit={verifyFormSubmit}
      />
    </Stack>
  );
}
