import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetEditJobLink } from '@common/hooks/appPageLinks';
import { useReset, useSteps } from '@common/hooks/multiStep';
import { useUpdateSourceQuery } from '@common/hooks/useUpdateSourceQuery';
import { Box } from '@components/Box';
import { CajaWrapper } from '../components';
import { getSteps } from './getSteps';
import { EditJobSuccess } from './steps/EditJobSuccess';
import { EditJob as MultiStepEditJob } from './steps/editJob/editJob';
import { getSteps as getEditJobSteps } from './steps/editJob/getSteps';

export function EditJob() {
  useUpdateSourceQuery();
  const { jobId } = useParams<{ jobId: string }>();
  const editJobLink = useGetEditJobLink(jobId);

  const { steps, currentStep, currentStepIndex, gotoNextStep, stepsToStepIds } =
    useSteps<any>({
      data: {},
      getSteps: () => getSteps()
    });

  useReset(currentStepIndex, editJobLink);

  const onPostJobCompleted = () => {
    gotoNextStep({});
  };

  const editJobSteps = getEditJobSteps({});

  return (
    <CajaWrapper
      title={'editJobPage.heading'}
      step={{
        index: currentStepIndex,
        title: currentStep.title,
        total: steps.length
      }}
    >
      <Box padding="small">
        {stepsToStepIds(editJobSteps).includes(currentStep.id) && (
          <MultiStepEditJob
            onNext={() => {}}
            onCompleted={onPostJobCompleted}
          />
        )}

        {currentStep.id === 'editJobSuccess' && (
          <EditJobSuccess jobId={jobId!!} />
        )}
      </Box>
    </CajaWrapper>
  );
}
