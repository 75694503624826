import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { SingleSelect } from '@common/components/FormElements';
import { JOB_TYPES } from '@seek/je-shared-data';
import { Box } from '@components/Box';
import { Field } from '@components/FormElements';
import { useJobTypeRadio } from './hooks/useJobTypeRadio';

type JobTypeRadioProps = {
  value: JOB_TYPES | undefined;
  onChange: () => void;
  error?: string;
};

export const JobTypeRadioField = (props: JobTypeRadioProps) => {
  const { t } = useTranslation();
  const { options } = useJobTypeRadio();
  const { error, onChange, value } = props;

  return (
    <Field
      label={t('postJobForm.fieldLabel.jobType')}
      message={
        error && (
          <Box marginTop="xsmall">
            <ErrorMessage>{error}</ErrorMessage>
          </Box>
        )
      }
    >
      <Box marginTop="xsmall">
        <SingleSelect
          testID="job-form-jobType"
          onChange={onChange}
          options={options}
          value={value}
        />
      </Box>
    </Field>
  );
};
