import React from 'react';
import { T } from '@common/hooks/useTranslation';
import { DAY, ShiftAvailability } from '@seek/je-shared-data/lib/types/jobs';

export const getShiftAvailabilitySummary = (
  shiftAvailability: ShiftAvailability | undefined,
  t: T
) => {
  if (!shiftAvailability) {
    return null;
  }

  return Object.values(DAY).map((day) => {
    if (!shiftAvailability?.specificShiftAvailability?.[day].length) {
      return null;
    }

    return (
      <li key={day}>
        <span>
          {t(`postJobForm.shiftAvailability.shiftDay.${day}`) + ': '}
          {shiftAvailability?.specificShiftAvailability?.[day]
            .map((shiftTime) =>
              t(
                `postJobForm.shiftAvailability.shiftTime.${shiftTime.toLowerCase()}`
              )
            )
            .join(', ')}
        </span>
      </li>
    );
  });
};
