import { Platform } from 'react-native';
import { useTheme } from '@theme/DesignSystemProvider/ThemeContext';
import { colors } from '@theme/tokens/colors';
import { useResolveToken } from '@theme/tokens/utils/useResolveToken';

export type InputTone = 'neutral' | 'success' | 'danger' | 'successMuted';

type InputStyleProps = {
  tone?: InputTone;
  hasPrefixIcon?: boolean;
  expanded?: boolean;
  fullWidth?: boolean;
};

export function useInputStyle({
  tone = 'neutral',
  hasPrefixIcon = false,
  expanded = false,
  fullWidth = false
}: InputStyleProps) {
  const { formElement, spacing, border } = useTheme();
  const { resolveToken } = useResolveToken();
  const { container, color: formElementColor } = formElement;
  const { borderColor } = formElementColor[tone];

  return {
    container: {
      maxWidth: fullWidth ? '100%' : container.maxWidth
    },
    prefixIconContainer: {
      position: 'absolute',
      padding: resolveToken(spacing, container.paddingTop)
    },
    inputField: {
      paddingTop: resolveToken(spacing, container.paddingTop),
      paddingBottom: resolveToken(spacing, container.paddingBottom),
      paddingHorizontal: resolveToken(spacing, container.paddingHorizontal),
      borderWidth: resolveToken(border.width, container.borderWidth),
      borderStyle: container.borderStyle,
      borderRadius: resolveToken(spacing, container.borderRadius),
      borderColor,
      height: 50,
      ...(Platform.OS === 'web' ? { outlineStyle: 'none' } : {}),
      ...(expanded && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0
      }),
      ...(hasPrefixIcon && { paddingLeft: 46 })
    },
    placeholder: {
      color: resolveToken(colors, 'grey600')
    }
  } as const;
}

export function useTextStyle({ tone = 'neutral' }: InputStyleProps) {
  const textStyleValues = {
    size: 'standard',
    tone: tone,
    weight: 'light'
  } as const;

  const {
    typography: {
      fontFamily,
      text,
      fontSizes,
      fontWeights,
      lineHeights,
      letterSpacing
    },
    formElement
  } = useTheme();
  const { resolveToken } = useResolveToken();
  const { color: formElementColors } = formElement;
  const { fontColor } = formElementColors[tone];

  const {
    size: textSize,
    lineHeight: textLineHeight,
    letterSpacing: textLetterSpacing
  } = resolveToken(text, textStyleValues.size) || {};

  return {
    fontFamily,
    fontSize: resolveToken(fontSizes, textSize),
    lineHeight: resolveToken(lineHeights, textLineHeight),
    fontWeight: resolveToken(fontWeights, textStyleValues.weight),
    color: fontColor,
    letterSpacing: resolveToken(letterSpacing, textLetterSpacing),
    ...(Platform.OS === 'android'
      ? ({ textAlignVertical: 'top' } as const)
      : {})
  };
}
