import React from 'react';
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';
import { useTranslation } from '@common/hooks/useTranslation';
import {
  JobDescriptionLength,
  OnInvalidDescriptionCallback
} from '../../MultiStepPostJob/types';
import { jobDescriptionRules } from '../../rules';
import { JobDescriptionField } from './JobDescriptionField';

type JobDescriptionProps = {
  jobDescriptionLength: JobDescriptionLength;
  onInvalidDescription: OnInvalidDescriptionCallback;
};

export default function JobDescription<T extends FieldValues>({
  name,
  control,
  jobDescriptionLength,
  onInvalidDescription
}: UseControllerProps<T> & JobDescriptionProps) {
  const { t } = useTranslation();

  return (
    <Controller
      name={name}
      control={control}
      rules={jobDescriptionRules(t, jobDescriptionLength, onInvalidDescription)}
      render={({ field: { onChange, onBlur, value }, formState }) => (
        <JobDescriptionField
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          placeholder={t('postJobForm.jobDescription.placeholder')}
          error={formState?.errors[name]?.message as string}
        />
      )}
    />
  );
}
