import { useForm } from 'react-hook-form';
import { T, useTranslation } from '@common/hooks/useTranslation';

export function useFormValidator() {
  const formInstance = useForm();
  const { t } = useTranslation();

  return async (
    rules: Record<string, (t: T) => any> | undefined,
    values: Record<string, any>
  ) => {
    formInstance.reset({ ...values }, { keepDefaultValues: true });
    if (!rules) return {};
    Object.keys(rules).forEach((key) => {
      formInstance.register(key, {
        ...rules[key](t)
      });
    });
    return await formInstance.trigger();
  };
}
