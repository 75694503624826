import React from 'react';
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';
import { useTranslation } from '@common/hooks/useTranslation';
import { RedirectTo } from '@common/search';
import { BusinessWithUsers } from '@store/entities/businesses/types';
import { PostJobFormBusiness } from '../../MultiStepPostJob/types';
import { businessRules } from '../../rules';
import { BusinessInformation } from './BusinessInformation';

type BusinessProps = {
  isBusinessEditable: boolean;
  shouldRedirectBusinessCreatedTo: RedirectTo;
};

export default function Business<T extends FieldValues>({
  name,
  control,
  isBusinessEditable,
  shouldRedirectBusinessCreatedTo
}: UseControllerProps<T> & BusinessProps) {
  const { t } = useTranslation();
  const interceptOnChange = (data: BusinessWithUsers, onChange): void => {
    onChange(toJobFormBusiness(data));
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={businessRules(t)}
      render={({ field: { onChange, value }, formState }) => (
        <BusinessInformation
          business={value}
          isBusinessEditable={isBusinessEditable}
          shouldRedirectBusinessCreatedTo={shouldRedirectBusinessCreatedTo}
          handleBusinessSelected={(business) =>
            interceptOnChange(business, onChange)
          }
          error={formState?.errors[name]?.message as string}
        />
      )}
    />
  );
}

const toJobFormBusiness = (
  business: BusinessWithUsers
): PostJobFormBusiness => ({
  id: business.id,
  name: business.name,
  recruitmentCompanyName: business.recruitmentCompanyName,
  geolocation: business.locations?.[0],
  businessSize: business.businessSize
});
