import { BusinessWithUsers } from '@store/entities/businesses/types';
import { useAppSelector } from '@store/hooks';
import { getRecentlyUsedBusinessId } from '@store/userData';

export const useDefaultBusiness = (
  businesses: BusinessWithUsers[]
): BusinessWithUsers => {
  const recentlyUsedBusiness = useRecentlyUsedBusiness(businesses);
  const recentlyCreatedBusiness = businesses[0];

  return recentlyUsedBusiness || recentlyCreatedBusiness;
};

const useRecentlyUsedBusiness = (businesses: BusinessWithUsers[]) => {
  const recentlyUsedBusinessId = useAppSelector(getRecentlyUsedBusinessId);

  if (recentlyUsedBusinessId) {
    return businesses.find(
      (business) => business.id === recentlyUsedBusinessId
    );
  }
};
