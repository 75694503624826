import React from 'react';
import CreateBusinessForm from '@common/components/Business/forms/createBusiness/BusinessForm';
import { CompleteBusinessFormData } from '@common/components/Business/forms/createBusiness/types';
import { useCreateBusiness } from '@common/hooks/business';
import { useRedirectUnauthenticatedUser } from '@common/hooks/useRedirectUnauthenticatedUser';
import { useGetSourceParams } from '@common/hooks/useSearchQueryParam';
import { useShowCountrySelector } from '@common/hooks/useShowCountrySelector';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';
import { Stack } from '@components/Stack';

type CreateBusinessProps = {
  onSubmit: (stepData: Partial<CompleteBusinessFormData>) => void;
  submitBtnText: string;
};

export function CreateBusiness({
  onSubmit,
  submitBtnText
}: CreateBusinessProps) {
  useRedirectUnauthenticatedUser();

  const createBusiness = useCreateBusiness();
  const sourceParams = useGetSourceParams();
  const showCountrySelector = useShowCountrySelector();
  const { countryCode: siteCountryCode } = useAppSelector(getLocalisation);

  // Create business
  const onCreateBusinessSubmit = async (business: CompleteBusinessFormData) => {
    const { isSuccessful, data } = await createBusiness({
      business,
      sourceParams
    });

    if (isSuccessful) {
      onSubmit(data);
    }
  };

  return (
    <Stack paddingTop="medium" padding="large">
      <CreateBusinessForm
        defaultValues={{ country: siteCountryCode }}
        onSubmit={onCreateBusinessSubmit}
        submitBtnText={submitBtnText}
        showCountrySelector={showCountrySelector}
      />
    </Stack>
  );
}
