import React from 'react';
import { When } from '@common/components/When';
import REQUEST_KEY from '@common/constants/requestKeys';
import { Application } from '@common/types';
import { INTERACTION_TYPE } from '@store/entities/applications/constants';
import { Box } from '@components/Box';
import { Card, CardContent } from '@components/Card';
import { Column } from '@components/Column';
import { Row } from '@components/Row';
import {
  ApplicantAvatar,
  ApplicantName,
  ApplicantStatusSelector,
  ApplicationCardActions as ApplicationCardFooterActions,
  CardDateAndApplicantStatusBadge,
  ChatWithApplicantModal,
  CoverLetterAndResumeStatusMessages,
  CurrentPositionInfo,
  LocationInfo,
  Notes,
  ProfileDetails,
  RevealEmailAddress,
  RevealPhoneNumber,
  SuitabilityBadges
} from './components';
import { useViewProfileAction } from './components/ApplicationCardActions/components/ViewProfileAction/useViewProfileAction';
import { useApplicationCard } from './useApplicationCard';

type ApplicationCardProps = {
  jobId: string;
  application: Application;
  jobCountryCode?: string;
};

export const ApplicationCard = (props: ApplicationCardProps) => {
  const { application, jobId, jobCountryCode } = props;

  const { isProfileExpanded, onViewProfilePressed } =
    useViewProfileAction(application);

  const { handleStatusChange, applicationDetails } =
    useApplicationCard(application);

  const {
    id,
    candiAppliedAt,
    applicantStatus,
    suitabilityBadges,
    notes,
    notesUpdatedAt,
    name
  } = applicationDetails;

  return (
    <Card marginBottom="medium" testID={`${id}-application-card`}>
      <CardDateAndApplicantStatusBadge
        date={candiAppliedAt}
        applicantId={id}
        applicantStatus={applicantStatus}
      />
      <CardContent paddingBottom="large">
        <Row flexWrap="wrap">
          <Column
            spacing="small"
            width={{
              mobile: 'full',
              tablet: '2/3',
              desktop: '2/3'
            }}
          >
            <ApplicantNameAndRoleSummary application={application} />

            <When condition={suitabilityBadges && suitabilityBadges.length > 0}>
              <SuitabilityBadges
                testID={`${id}-suitability-badges`}
                suitabilityBadges={suitabilityBadges}
                distanceFromJob={application.distanceFromJobKm}
                yearsOfExperienceInJobRole={
                  application.yearsOfExperienceInJobRole
                }
              />
            </When>

            <ContactInfoAndProfileSummary
              application={application}
              jobId={jobId}
              jobCountryCode={jobCountryCode}
            />
          </Column>

          <Column
            width={{
              mobile: 'full',
              tablet: '1/3',
              desktop: '1/3'
            }}
            marginTop={{
              mobile: 'medium',
              tablet: 'none',
              desktop: 'none'
            }}
            alignItems={{
              mobile: 'center',
              tablet: 'flex-end',
              desktop: 'flex-end'
            }}
          >
            <ApplicantStatusSelector
              requestKey={REQUEST_KEY.APPLICATION.UPDATE_APPLICANT_STATUS_BY_ID(
                id
              )}
              currentStatus={applicantStatus}
              onChange={handleStatusChange}
              testID={`${id}-applicant-status`}
            />
          </Column>
        </Row>

        <Notes
          applicationId={id}
          applicantName={name as string}
          notes={notes}
          notesUpdatedAt={notesUpdatedAt}
        />

        <When condition={isProfileExpanded}>
          <ProfileDetails
            applicationId={application.id}
            shiftAvailability={application.shiftAvailability}
            workExperience={application.workExperience}
            summary={application.aboutMe}
            candiRightToWork={application.candiRightToWork}
            age={application.age}
            gender={application.gender}
            citizenship={application.citizenship}
            jobCountryCode={jobCountryCode}
            highestEducation={application.highestEducation}
          />
        </When>

        <CoverLetterAndResumeStatusMessages application={application} />
      </CardContent>
      <ApplicationCardFooterActions
        application={application}
        jobId={jobId}
        viewProfileActionProps={{
          isProfileExpanded,
          onViewProfilePressed
        }}
      />
    </Card>
  );
};

const ApplicantNameAndRoleSummary = ({
  application
}: Pick<ApplicationCardProps, 'application'>) => {
  const { currentPositionDurationText, applicationDetails } =
    useApplicationCard(application);

  const { id, name, currentPosition } = applicationDetails;

  return (
    <Row spacing="medium">
      <When condition={Boolean(name)}>
        <ApplicantAvatar testID={`${id}-candidate-avatar`} name={name || ''} />
      </When>
      <Column
        spacing="xsmall"
        width="full"
        flexShrink={1}
        justifyContent="center"
      >
        <When condition={Boolean(name)}>
          <ApplicantName testID={`${id}-applicant-name`} name={name || ''} />
        </When>
        <CurrentPositionInfo
          currentPosition={currentPosition}
          currentPositionDuration={currentPositionDurationText}
          testID={`${id}-applicant-current-role`}
        />
      </Column>
    </Row>
  );
};

const ContactInfoAndProfileSummary = (props: ApplicationCardProps) => {
  const { application, jobId } = props;

  const { chatWithApplicantModal, trackInteraction, applicationDetails } =
    useApplicationCard(application);

  const { isModalOpen, openModal, closeModal } = chatWithApplicantModal;

  const { id, email, applicantPhoneNumber, location } = applicationDetails;

  return (
    <Column marginTop="small" spacing="xsmall">
      <When condition={Boolean(email)}>
        <RevealEmailAddress
          onReveal={() => trackInteraction(INTERACTION_TYPE.REVEALED_EMAIL)}
          onLaunchMailClient={() =>
            trackInteraction(INTERACTION_TYPE.CLICKED_EMAIL)
          }
          emailAddress={email || ''}
        />
      </When>

      <When condition={Boolean(applicantPhoneNumber)}>
        <Box>
          <RevealPhoneNumber
            phoneNumber={applicantPhoneNumber || ''}
            handlePhoneLinkClicked={() => {
              openModal();
              trackInteraction(INTERACTION_TYPE.CLICKED_PHONE_NUMBER);
            }}
            onReveal={() =>
              trackInteraction(INTERACTION_TYPE.REVEALED_PHONE_NUMBER)
            }
          />
          <ChatWithApplicantModal
            jobId={jobId}
            application={application}
            modalOpen={isModalOpen}
            onModalClose={closeModal}
            testID={`${id}-chat-with-applicant-modal`}
          />
        </Box>
      </When>

      <LocationInfo location={location?.displayAddress} />
    </Column>
  );
};
