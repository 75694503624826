import { useCallback } from 'react';
import REQUEST_KEY from '@common/constants/requestKeys';
import { getRequestState } from '@store/app';
import { getJobsList, loadDashboardJobs } from '@store/entities/jobs';
import { useAppDispatch, useAppSelector } from '@store/hooks';

export const useGetDashboardJobs = () => {
  const dispatch = useAppDispatch();
  const dashboardJobs = useAppSelector(getJobsList());

  const loadJobListError = useAppSelector(
    getRequestState(REQUEST_KEY.JOB.LIST)
  ).error;

  const getDashboardJobs = useCallback(() => {
    dispatch(loadDashboardJobs());
  }, [dispatch]);

  return {
    getDashboardJobs,
    hasLoadJobListError: Boolean(loadJobListError),
    error: loadJobListError,
    dashboardJobs
  };
};
