import { useEffect } from 'react';
import { useGetDashboardJobs } from '@common/hooks/job/useGetDashboardJobs';
import {
  useShowJobLimitReached,
  useShowOverBusinessLimitModal
} from '@common/hooks/userPackages';

export const useJobAndBusinessLimits = () => {
  // Loading jobs into state is needed to check if limit is reached
  const { getDashboardJobs } = useGetDashboardJobs();
  useEffect(() => getDashboardJobs(), [getDashboardJobs]);

  const showJobLimitReached = useShowJobLimitReached();
  const showOverBusinessLimitBlockingModal = useShowOverBusinessLimitModal();

  return {
    showJobLimitReached,
    showOverBusinessLimitBlockingModal
  };
};
