import { Step } from '@common/hooks/multiStep/types';
import { getSteps as getPostJobSteps } from './steps/repostJob/getSteps';

export const getSteps = ({ isUpgradeable }): Step[] => {
  const steps = {
    upgradeJob: {
      id: 'upgradeJob',
      path: '/upgrade-job',
      title: 'upgradeJobStep.stepTitle',
      pageTitle: 'upgradeJobStep.heading'
    },
    postJobSuccess: {
      id: 'postJobSuccess',
      path: '/post-job-success',
      title: '',
      pageTitle: ''
    }
  };

  const { upgradeJob, postJobSuccess } = steps;

  const postJobSteps = getPostJobSteps({});

  if (isUpgradeable) {
    return [...postJobSteps, upgradeJob];
  }

  return [...postJobSteps, postJobSuccess];
};
