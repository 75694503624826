import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  MultiStepPostJob,
  PostJobFormData
} from '@common/components/Job/forms/MultiStepPostJob';
import {
  trackMaxLengthValidationError,
  trackMinLengthValidationError
} from '@common/components/Job/forms/fields/JobDescription/jobDescriptionActions';
import {
  JOB_DESCRIPTION_MAX_LENGTH,
  JOB_DESCRIPTION_MULTISTEP_MIN_LENGTH
} from '@common/constants/validation';
import { useLoadBusiness } from '@common/hooks/business';
import { useGetJobForEdit } from '@common/hooks/job';
import { useUpdateJob } from '@common/hooks/job/useUpdateJob';
import { useAppDispatch } from '@store/hooks';
import { getSteps } from './getSteps';
import { toEditJobFormData as mapToFormData } from './transformers/toEditJobFormData';
import {
  CompleteEditJobFormData,
  toUpdateJobParams
} from './transformers/toUpdateJobParams';

export interface InvalidDescriptionData {
  amount: number;
  requiredAmount: number;
  reason: 'MAX_LENGTH' | 'MIN_LENGTH';
}

export const EditJob = ({
  onNext,
  onCompleted
}: {
  onNext: (stepData: PostJobFormData) => void;
  onCompleted: () => void;
}) => {
  const { jobId } = useParams<{ jobId: string }>();
  const dispatch = useAppDispatch();

  const [jobUpdates, setJobUpdates] = useState<Partial<PostJobFormData>>({});

  const {
    data: job,
    isLoading: jobLoading,
    error: loadJobError
  } = useGetJobForEdit({ jobId });
  const {
    data: business,
    isLoading: businessLoading,
    error: loadBusinessError
  } = useLoadBusiness(job?.businessId);

  const jobFormData = mapToFormData(jobUpdates, job, business);

  const { updateJobData, error: updateJobError } = useUpdateJob();

  const isLoading = businessLoading || jobLoading;
  const error = updateJobError || loadJobError || loadBusinessError;

  const updateJobOnCompleted = async (data: CompleteEditJobFormData) =>
    await updateJobData(toUpdateJobParams(jobId, data));

  const onNextStep = (data: PostJobFormData) => {
    setJobUpdates({ ...jobUpdates, ...data });
    onNext(data);
  };

  const onPostJobCompleted = async (data: PostJobFormData) => {
    const completeFormData = data as CompleteEditJobFormData;
    const response = await updateJobOnCompleted(completeFormData);
    if (!response?.error) {
      onCompleted();
    }
  };

  const handleInvalidDescription = (data: InvalidDescriptionData) => {
    if (data.reason === 'MIN_LENGTH') {
      dispatch(trackMinLengthValidationError());
    } else if (data.reason === 'MAX_LENGTH') {
      dispatch(trackMaxLengthValidationError());
    }
  };

  if (!business && !loadBusinessError && !loadJobError) return null;

  return (
    <MultiStepPostJob
      getFormSteps={getSteps}
      onCompleted={onPostJobCompleted}
      isLoading={isLoading}
      jobData={jobFormData}
      error={error}
      onNext={onNextStep}
      formOptions={{
        jobDescriptionLength: {
          min: JOB_DESCRIPTION_MULTISTEP_MIN_LENGTH,
          max: JOB_DESCRIPTION_MAX_LENGTH
        },
        onInvalidDescription: handleInvalidDescription,
        isBusinessEditable: false
      }}
    />
  );
};
