import { createContext } from 'react';
import { RedirectTo } from '@common/search';
import { JobDescriptionLength, OnInvalidDescriptionCallback } from './types';

type MultiStepPostJobContextProps = {
  redirectCreateBusinessTo: RedirectTo;
  isBusinessEditable: boolean;
  jobDescriptionLength: JobDescriptionLength;
  onInvalidDescription: OnInvalidDescriptionCallback;
};

export const MultiStepPostJobContext =
  createContext<MultiStepPostJobContextProps>(
    {} as MultiStepPostJobContextProps
  );
