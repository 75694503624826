import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Application, TargetLocation } from '@common/types';
import { VIDEO_SCREENING_STATUS } from '@seek/je-shared-data/lib/types/applications';
import { LocationIcon } from '@components/Icon/icons/LocationIcon';
import { VideoIcon } from '@components/Icon/icons/VideoIcon';
import { PillToggle } from '@components/PillToggle';
import {
  ApplicationFilterValues,
  FILTERS
} from '../../../../../../../../types';
import { PlusMaxBadge } from '../../../../../PlusMaxBadge';
import { ClearLink } from '../ClearLink';

type QuickFiltersFilterForm = Pick<
  ApplicationFilterValues,
  FILTERS.VIDEO_SCREENING | FILTERS.TARGET_LOCATION
>;

type QuickFiltersValuesKey = keyof QuickFiltersFilterForm;

type QuickFiltersProps = {
  applications: Application[];
  disabled?: boolean;
  values: QuickFiltersFilterForm;
  targetLocations: TargetLocation[];
  handleFieldChange: <K extends QuickFiltersValuesKey>(
    field: K
  ) => (value: QuickFiltersFilterForm[K]) => void;
  handleMultipleFieldClear: (fields: QuickFiltersValuesKey[]) => () => void;
};

export const QuickFilters = ({
  applications = [],
  disabled = false,
  values,
  targetLocations,
  handleFieldChange,
  handleMultipleFieldClear
}: QuickFiltersProps) => {
  const { t } = useTranslation();

  const handleOnClear = useCallback(() => {
    if (disabled) {
      return;
    }

    handleMultipleFieldClear([
      FILTERS.VIDEO_SCREENING,
      FILTERS.TARGET_LOCATION
    ])();
  }, [disabled, handleMultipleFieldClear]);

  const hasVideoScreening = !!values[FILTERS.VIDEO_SCREENING];
  const hasTargetLocations = (values[FILTERS.TARGET_LOCATION] ?? []).length > 0;

  const isClearActive = !disabled && (hasVideoScreening || hasTargetLocations);

  const videoScreeningCount = applications.filter(
    ({ videoScreeningStatus }) =>
      videoScreeningStatus === VIDEO_SCREENING_STATUS.VIDEO_READY
  ).length;
  const videoScreeningCountText = t(
    'applications.applicationsFilter.quickFilters.videoScreening'
  );
  const videoScreeningCountLabel =
    videoScreeningCount > 0
      ? `${videoScreeningCountText} (${videoScreeningCount})`
      : videoScreeningCountText;

  return (
    <div className="space-y-md">
      <div className="flex justify-between">
        <div className="flex justify-start gap-md">
          <div className="text-md">
            {t('applications.applicationsFilter.quickFilters.title')}
          </div>
          {disabled && <PlusMaxBadge />}
        </div>
        <ClearLink onClear={handleOnClear} active={isClearActive} />
      </div>

      <div className="flex flex-wrap flex-row gap-md">
        <PillToggle
          label={videoScreeningCountLabel}
          checked={values[FILTERS.VIDEO_SCREENING] ?? false}
          onChange={handleFieldChange(FILTERS.VIDEO_SCREENING)}
          disabled={disabled}
          icon={<VideoIcon size="xlarge" tone="success" variant="outline" />}
        />
        {targetLocations.map((location) => {
          const targetedLocationCount = applications.filter(
            // @ts-expect-error: targetedLocation property will be added in future PR
            ({ targetedLocation }) => targetedLocation?.id === location.id
          ).length;
          const targetedLocationCountText = t(
            'applications.applicationsFilter.quickFilters.location',
            { location: location.name }
          );
          const targetedLocationCountLabel =
            targetedLocationCount > 0
              ? `${targetedLocationCountText} (${targetedLocationCount})`
              : targetedLocationCountText;

          return (
            <PillToggle
              key={`targeted-location-${location.id}`}
              label={targetedLocationCountLabel}
              checked={
                values[FILTERS.TARGET_LOCATION]?.some(
                  (val) => val.id === location.id
                ) ?? false
              }
              onChange={(checked) => {
                const currentLocations = values[FILTERS.TARGET_LOCATION] || [];
                handleFieldChange(FILTERS.TARGET_LOCATION)(
                  checked
                    ? [...currentLocations, location]
                    : currentLocations.filter((loc) => loc.id !== location.id)
                );
              }}
              disabled={disabled}
              icon={
                <LocationIcon size="xlarge" tone="success" variant="outline" />
              }
            />
          );
        })}
      </div>
    </div>
  );
};
