/**
 * Form variant for MultiStepPostJob form.
 * This is used to identify the form and its version.
 * When there is a breaking change to the form, the major version should be incremented.
 * When there is a non-breaking change to the form, the minor version should be incremented.
 * This is to help with backwards compatibility when loading drafts.
 */
export const formVariant = {
  formVariant: 'default_v1'
};
