import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { AppDownloadReminderBanner } from '@common/components/AppDownloadReminderBanner';
import { JoraEmployerHelmet } from '@common/components/Helmet';
import { useRedirectUnauthenticatedUser } from '@common/hooks/useRedirectUnauthenticatedUser';
import { Button } from '@components/Button';
import { Card, CardContent } from '@components/Card';
import { Column } from '@components/Column';
import { Heading } from '@components/Heading';
import { JobSuccessIllustration } from '@components/Illustration';
import { List } from '@components/List';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';
import { usePostJobSuccess } from './usePostJobSuccess';

const PostJobSuccessActions = () => {
  const { t } = useTranslation();
  const { redirectToHomePage, redirectToPostJobPage } = usePostJobSuccess();

  return (
    <Stack
      marginTop="medium"
      spacing="medium"
      direction={{ mobile: 'column', tablet: 'row', desktop: 'row' }}
    >
      <Button onPress={redirectToPostJobPage}>
        {t('postJobSuccessPage.action.postAnotherJob')}
      </Button>

      <Button onPress={redirectToHomePage} variant="secondary">
        {t('common.action.backToHome')}
      </Button>
    </Stack>
  );
};

const PostJobSuccessCopy = () => {
  const { brandNameText, isRealTimeJobPostingBrand } = usePostJobSuccess();
  const { t } = useTranslation();

  if (isRealTimeJobPostingBrand) {
    return (
      <Stack spacing="medium" testID="live-job-post-content">
        <Heading level="h2">
          {t('postJobSuccessPage.temporary.firstTimePoster.heading', {
            brandNameText
          })}
        </Heading>
        <Text>
          {t('postJobSuccessPage.temporary.firstTimePoster.description')}
        </Text>

        <Heading level="h2">
          {t('postJobSuccessPage.temporary.verifiedPoster.heading', {
            brandNameText
          })}
        </Heading>
        <Text>
          {t('postJobSuccessPage.temporary.verifiedPoster.description')}
        </Text>
      </Stack>
    );
  }

  return (
    <Stack spacing="medium" testID="non-live-job-post-content">
      <Heading level="h2">
        {t('postJobSuccessPage.description.whatHappensNow')}
      </Heading>
      <List variant="bullet">
        <Text>{t('postJobSuccessPage.description.weAreReviewing')}</Text>
        <Text>{t('postJobSuccessPage.description.ifYouAlready')}</Text>
      </List>

      <Heading level="h2">
        {t('postJobSuccessPage.description.whatHappensNext')}
      </Heading>
      <Text>{t('postJobSuccessPage.description.startManagingJobAds')}</Text>
      <List variant="number">
        <Text>{t('postJobSuccessPage.description.editOrCloseJob')}</Text>
        <Text>
          {t('postJobSuccessPage.description.reviewAndShortlistApplicants')}
        </Text>
      </List>
    </Stack>
  );
};

export const PostJobSuccess = () => {
  useRedirectUnauthenticatedUser();
  const { t } = useTranslation();
  const history = useHistory();
  // Get the created job id from the URL
  const { jobId } = useParams<{ jobId: string }>();

  // BAD hack for when the user clicks 'submit' on the job description step
  // Then go to 'postJobSuccess' step (because they are unmonitized)
  // They think they are adding a description to the job they just created, but they aren't,
  // A new draft is created, then they submit that and it borks the submit (as title etc are required)
  useEffect(() => {
    if (!history || !history.block) return;
    const unblock = history.block((location, action) => {
      if (action === 'POP') {
        const stepName = location.pathname.split('/').pop();
        if (
          stepName === 'review-job-description' ||
          stepName === 'upgrade-job'
        ) {
          const urlFirstPart = history.location.pathname.split('/')[1];
          history.push(
            `/${urlFirstPart}/jobs/${jobId}/edit-job/review-job-description`
          );
          return false;
        }
      }
    });

    return () => {
      unblock();
    };
  }, [history, jobId]);

  return (
    <>
      <JoraEmployerHelmet title={t('postJobSuccessPage.title')} />
      <Card>
        <CardContent>
          <Stack alignItems="center" spacing="medium">
            <JobSuccessIllustration size="standard" />
            <Heading level="h1" weight="regular">
              {t('postJobSuccessPage.heading')}
            </Heading>
          </Stack>
        </CardContent>
      </Card>

      <Column marginTop="medium" marginBottom="medium">
        <Card>
          <CardContent>
            <PostJobSuccessCopy />
            <PostJobSuccessActions />
          </CardContent>
        </Card>
      </Column>

      <Column marginBottom="medium">
        <AppDownloadReminderBanner testID="app-download-banner" />
      </Column>
    </>
  );
};
