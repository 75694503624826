import { RightToWork } from '@common/constants/data';
import {
  ApplicationStatus,
  HighestEducation,
  ProfileLocation,
  TargetLocation
} from '@common/types';
import { WorkExperience } from '@seek/je-shared-data/lib/types/jobs';

export enum FILTERS {
  APPLICANT_STATUS = 'applicant_status',
  CITIZENSHIP = 'citizenship',
  DISTANCE = 'distance',
  EDUCATION = 'education',
  LOCATION = 'location',
  RIGHT_TO_WORK = 'right_to_work',
  SORT_APPLICATIONS = 'sort_applications',
  TARGET_LOCATION = 'target_location',
  VIDEO_SCREENING = 'video_screening',
  WORK_EXPERIENCE = 'work_experience'
}

export enum SORT {
  APPLICATION_DATE = 'applicationDate',
  DISTANCE = 'distance',
  RIGHT_TO_WORK = 'rightToWork'
}

export type ApplicationSortValue =
  | SORT.APPLICATION_DATE
  | SORT.DISTANCE
  | SORT.RIGHT_TO_WORK;

export type ApplicationFilterValues = {
  [FILTERS.SORT_APPLICATIONS]: ApplicationSortValue;
  [FILTERS.APPLICANT_STATUS]?: ApplicationStatus[];
  [FILTERS.CITIZENSHIP]?: string[];
  [FILTERS.DISTANCE]?: number;
  [FILTERS.EDUCATION]?: HighestEducation[];
  [FILTERS.LOCATION]?: ProfileLocation[];
  [FILTERS.RIGHT_TO_WORK]?: RightToWork[];
  [FILTERS.TARGET_LOCATION]?: TargetLocation[];
  [FILTERS.VIDEO_SCREENING]?: boolean;
  [FILTERS.WORK_EXPERIENCE]?: WorkExperience[];
};
