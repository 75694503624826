import { PostJobFormData } from '@common/components/Job/forms/MultiStepPostJob';
import { DraftJob } from '@common/hooks/job/useFindRecentDraft';
import { BusinessWithUsers } from '@store/entities/businesses/types';

export const toPostJobFormData = (
  draft: DraftJob,
  business?: BusinessWithUsers
): PostJobFormData => {
  const formData: PostJobFormData = {
    jobTitle: { jobTitle: draft?.title, role: draft?.jobRole },
    workExperience: draft?.workExperience,
    salary: draft?.salary,
    jobType: draft?.jobType,
    shiftAvailability: draft?.shiftAvailability,
    rightToWork: draft?.rightToWork,
    immediateStart: draft?.immediateStart,
    jobDescription: draft?.description
  };

  if (business) {
    formData.business = {
      id: business.id,
      name: business.name,
      geolocation: business.locations[0],
      businessSize: business.businessSize,
      recruitmentCompanyName: business.recruitmentCompanyName
    };
  }

  return formData;
};
