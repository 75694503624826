import { Step } from '@common/hooks/multiStep/types';
import { getSteps as getEditJobSteps } from './steps/editJob/getSteps';

export const getSteps = (): Step[] => {
  const steps = {
    editJobSuccess: {
      id: 'editJobSuccess',
      path: '/edit-job-success',
      title: '',
      pageTitle: ''
    }
  };

  const { editJobSuccess } = steps;

  const postJobSteps = getEditJobSteps({});

  return [...postJobSteps, editJobSuccess];
};
