import { PostJobFormData } from '@common/components/Job/forms/MultiStepPostJob';
import { useUpdateDraft } from '@common/hooks/job/useUpdateDraft';
import { toUpdateDraftParams } from '../transformers/postJobFormData/toUpdateDraftParams';

export function useSaveDraft(repostJobId: string) {
  const { updateDraftAsync, error, isLoading } = useUpdateDraft();
  return {
    saveDraft: async (data: PostJobFormData) => {
      await updateDraftAsync({
        repostJobId,
        draft: toUpdateDraftParams(data)
      });
    },
    error,
    isLoading
  };
}
