import React from 'react';
import { JoraEmployerHelmet } from '@common/components/Helmet';
import { Box } from '@components/Box';
import { Heading } from '@components/Heading';
import { Stack } from '@components/Stack';
import { PostJob } from './PostJob';
import { UpgradeJobTestWidget } from './UpgradeJobTestWidget';

export const Playground = () => {
  return (
    <Stack tone="transparent">
      <JoraEmployerHelmet title={'Playground'} />
      <Stack tone="transparent" spacing="medium" paddingBottom="xlarge">
        <Box paddingTop="medium" paddingLeft="medium" tone="transparent">
          <Heading level="h1">Welcome to the Playground</Heading>
        </Box>

        <UpgradeJobTestWidget />
        <PostJob />
      </Stack>
    </Stack>
  );
};
