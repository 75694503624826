import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Trans as I18nextTrans } from 'react-i18next';
import { AD_TYPE } from '@seek/je-shared-data';
import { PlusAdIcon } from '@components/Icon';
import { Row } from '@components/Row';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';
import { Benefit } from '../Benefit';
import { RecommendedUpgrade } from '../RecommendedUpgrade';
import { UpgradeInfo } from '../UpgradeInfo';
import { UpgradeWrapper } from '../UpgradeWrapper';

const OLD_JOB_DURATION_IN_DAYS_FOR_PROMO_TEXT = 7;
const LightText = <Text size="small" />;
const BoldText = <Text size="small" weight="medium" />;
const LineThroughText = <Text size="small" decoration="line-through" />;

type PlusAdUpgradeCardProps = {
  price: ReactElement;
  jobFeaturedForXDays: boolean;
  priorityPlacementOverBasic: boolean;
  xTimesMoreCandidateViews: boolean;
  adDuration?: {
    [AD_TYPE.BASIC]: number | undefined;
    [AD_TYPE.PLUS]: number | undefined;
    [AD_TYPE.MAX]: number | undefined;
  };
  hireCandidatesFaster?: boolean;
  jobFeaturedForXDaysPromo?: boolean;
  threeTimesMoreViews?: boolean;
  onButtonPress: () => void;
};

export const PlusAdUpgradeCard = ({
  adDuration,
  price,
  hireCandidatesFaster,
  jobFeaturedForXDays,
  jobFeaturedForXDaysPromo,
  priorityPlacementOverBasic,
  threeTimesMoreViews,
  xTimesMoreCandidateViews,
  onButtonPress
}: PlusAdUpgradeCardProps) => {
  const { t } = useTranslation();

  return (
    <UpgradeWrapper
      onChoose={onButtonPress}
      buttonText={t('upgradeJobForm.plus.upgradeCTA')}
    >
      <Row justifyContent="space-between" alignItems="center">
        <PlusAdIcon
          size="xxlarge"
          variant="outline"
          tone="success"
          testID="plus-ad-icon"
        />
        <RecommendedUpgrade />
      </Row>

      <Stack spacing="small" marginBottom="medium" dividers={true}>
        <UpgradeInfo name={t('upgradeJobForm.plus.name')} price={price} />
        <Stack spacing="small">
          {xTimesMoreCandidateViews && (
            <Text>
              <I18nextTrans
                i18nKey={'upgradeJobForm.benefits.xTimesMoreCandidateViews'}
                values={{
                  times: 3
                }}
                components={[BoldText]}
              />
            </Text>
          )}
          {jobFeaturedForXDays && adDuration && (
            <Benefit>
              <I18nextTrans
                i18nKey={'upgradeJobForm.benefits.jobFeaturedForXDays'}
                values={{
                  days: adDuration[AD_TYPE.PLUS],
                  duration: adDuration[AD_TYPE.PLUS]
                }}
              />
            </Benefit>
          )}
          {jobFeaturedForXDaysPromo && adDuration && (
            <Benefit>
              <I18nextTrans
                i18nKey={'upgradeJobForm.benefits.jobFeaturedForXDaysPromo'}
                values={{
                  days: OLD_JOB_DURATION_IN_DAYS_FOR_PROMO_TEXT,
                  duration: adDuration[AD_TYPE.PLUS]
                }}
                components={[LightText, LineThroughText]}
              />
            </Benefit>
          )}
          {hireCandidatesFaster && (
            <Benefit>
              <I18nextTrans
                i18nKey={'upgradeJobForm.benefits.hireCandidatesFaster'}
                components={[BoldText]}
              />
            </Benefit>
          )}
          {threeTimesMoreViews && (
            <Benefit>
              <I18nextTrans
                i18nKey={'upgradeJobForm.benefits.threeTimesMoreViews'}
                components={[BoldText]}
              />
            </Benefit>
          )}
          {priorityPlacementOverBasic && (
            <Benefit>
              <I18nextTrans
                i18nKey={'upgradeJobForm.benefits.priorityPlacementOverBasic'}
              />
            </Benefit>
          )}

          <Text weight="regular">
            {t('upgradeJobForm.benefits.easierShortlisting')}
          </Text>

          <Benefit>
            <I18nextTrans
              i18nKey={'upgradeJobForm.benefits.applicantVideoScreening'}
            />
          </Benefit>
        </Stack>
      </Stack>
    </UpgradeWrapper>
  );
};
