import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Trans as I18nextTrans } from 'react-i18next';
import { AD_TYPE } from '@seek/je-shared-data';
import { MaxAdIcon } from '@components/Icon';
import { Row } from '@components/Row';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';
import { Benefit } from '../Benefit';
import { UpgradeInfo } from '../UpgradeInfo';
import { UpgradeWrapper } from '../UpgradeWrapper';

const LightText = <Text size="small" />;
const BoldText = <Text size="small" weight="medium" />;
const LineThroughText = <Text size="small" decoration="line-through" />;

type MaxAdUpgradeCardProps = {
  price: ReactElement;
  featuredOnSocialMedia: boolean;
  fourTimesMoreSearchResultViews: boolean;
  hireCandidatesFaster: boolean;
  jobFeaturedForXDays: boolean;
  jobFeaturedForXDaysPromo: boolean;
  priorityPlacementOverBasicAndPlus: boolean;
  sixTimesMoreViews: boolean;
  xTimesMoreCandidateViews: boolean;
  onButtonPress: () => void;
  adDuration?: {
    [AD_TYPE.BASIC]: number | undefined;
    [AD_TYPE.PLUS]: number | undefined;
    [AD_TYPE.MAX]: number | undefined;
  };
};

export const MaxAdUpgradeCard = ({
  price,
  featuredOnSocialMedia,
  fourTimesMoreSearchResultViews,
  hireCandidatesFaster,
  jobFeaturedForXDays,
  jobFeaturedForXDaysPromo,
  priorityPlacementOverBasicAndPlus,
  sixTimesMoreViews,
  xTimesMoreCandidateViews,
  onButtonPress,
  adDuration
}: MaxAdUpgradeCardProps) => {
  const { t } = useTranslation();

  return (
    <UpgradeWrapper
      onChoose={onButtonPress}
      buttonText={t('upgradeJobForm.max.upgradeCTA')}
    >
      <Row justifyContent="space-between" alignItems="center">
        <MaxAdIcon
          size="xxlarge"
          variant="outline"
          tone="success"
          testID="max-ad-icon"
        />
      </Row>

      <Stack spacing="small" marginBottom="medium" dividers={true}>
        <UpgradeInfo name={t('upgradeJobForm.max.name')} price={price} />
        <Stack spacing="small">
          {xTimesMoreCandidateViews && (
            <Text>
              <I18nextTrans
                i18nKey={'upgradeJobForm.benefits.xTimesMoreCandidateViews'}
                values={{
                  times: 6
                }}
                components={[BoldText]}
              />
            </Text>
          )}
          {jobFeaturedForXDays && adDuration && (
            <Benefit>
              <I18nextTrans
                i18nKey={'upgradeJobForm.benefits.jobFeaturedForXDays'}
                values={{
                  days: adDuration[AD_TYPE.MAX],
                  duration: adDuration[AD_TYPE.MAX]
                }}
              />
            </Benefit>
          )}
          {jobFeaturedForXDaysPromo && adDuration && (
            <Benefit>
              <I18nextTrans
                i18nKey={'upgradeJobForm.benefits.jobFeaturedForXDaysPromo'}
                values={{
                  days: adDuration[AD_TYPE.MAX],
                  duration: adDuration[AD_TYPE.MAX]
                }}
                components={[LightText, LineThroughText]}
              />
            </Benefit>
          )}
          {sixTimesMoreViews && (
            <Benefit>
              <I18nextTrans
                i18nKey={'upgradeJobForm.benefits.sixTimesMoreViews'}
                components={[BoldText]}
              />
            </Benefit>
          )}
          {hireCandidatesFaster && (
            <Benefit>
              <I18nextTrans
                i18nKey={'upgradeJobForm.benefits.hireCandidatesFaster'}
                components={[BoldText]}
              />
            </Benefit>
          )}
          {priorityPlacementOverBasicAndPlus && (
            <Benefit>
              <I18nextTrans
                i18nKey={
                  'upgradeJobForm.benefits.priorityPlacementOverBasicAndPlus'
                }
              />
            </Benefit>
          )}
          {featuredOnSocialMedia && (
            <Benefit>
              <I18nextTrans
                i18nKey={'upgradeJobForm.benefits.featuredOnSocialMedia'}
                components={[BoldText]}
              />
            </Benefit>
          )}
          {fourTimesMoreSearchResultViews && (
            <Benefit>
              <I18nextTrans
                i18nKey={
                  'upgradeJobForm.benefits.fourTimesMoreSearchResultViews'
                }
                components={[BoldText]}
              />
            </Benefit>
          )}

          <Text weight="regular">
            {t('upgradeJobForm.benefits.easierShortlisting')}
          </Text>

          <Benefit>
            <I18nextTrans
              i18nKey={'upgradeJobForm.benefits.applicantVideoScreening'}
            />
          </Benefit>
        </Stack>
      </Stack>
    </UpgradeWrapper>
  );
};
