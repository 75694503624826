import { useEffect, useRef } from 'react';
import { useRedirect } from '@common/hooks/useRedirect';

/**
 * Custom hook to reset the form to the first step if the user
 * navigates away from the mutlistep component or hits refresh
 *
 * @param {string} stepIndex - The current step index
 * @param {string} redirectPath - The path to redirect to.
 */
export function useReset(stepIndex: number, redirectPath: string) {
  const isFirstLoad = useRef(true);
  const { redirect } = useRedirect();

  useEffect(() => {
    if (isFirstLoad.current && stepIndex !== 0) {
      redirect(redirectPath)();
    }

    isFirstLoad.current = false;
  }, [redirect, redirectPath, stepIndex]);
}
