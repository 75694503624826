import React from 'react';
import { Skeleton } from 'antd';
import { ClientError } from '@common/helpers/errors';
import { StepError, StepWrapper } from '../../components';
import { PostJobFormData } from '../../types';
import { DescriptionForm } from './DescriptionForm';
import { JobSummary } from './JobSummary';

type TitleAndBusinessStepProps = {
  isLoading: boolean;
  error?: ClientError;
  formData: PostJobFormData;
  stepInfo: {
    showBackButton: boolean;
    backText: string;
    submitText: string;
  };
  onSubmit: (data: PostJobFormData) => Promise<void>;
  onBack: () => void;
};

export const ReviewAndDescriptionStep = ({
  isLoading,
  error,
  formData,
  onSubmit,
  onBack,
  stepInfo
}: TitleAndBusinessStepProps) => {
  if (isLoading) {
    return (
      <StepWrapper>
        <Skeleton active />
      </StepWrapper>
    );
  }

  return (
    <StepWrapper>
      <div className="space-y-12">
        <JobSummary job={formData} />

        <DescriptionForm
          defaultValues={{ jobDescription: formData.jobDescription }}
          onSubmit={onSubmit}
          onBack={onBack}
          stepInfo={stepInfo}
        />
      </div>

      <StepError error={error} />
    </StepWrapper>
  );
};
