import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  type PostJobFormData,
  formVariant
} from '@common/components/Job/forms/MultiStepPostJob';
import { UpdateYourBusinessesModal } from '@common/components/UpdateYourBusinessesModal';
import { useGetSignUpLink } from '@common/hooks/appPageLinks';
import { useSteps } from '@common/hooks/multiStep';
import { useUpgradePermissions } from '@common/hooks/payment';
import { useRedirectUnauthenticatedUser } from '@common/hooks/useRedirectUnauthenticatedUser';
import { useUpdateSourceQuery } from '@common/hooks/useUpdateSourceQuery';
import { JobLimitReached } from '@src/joraEmployer/pages/job/job-limit-reached';
import { Box } from '@components/Box';
import { CajaWrapper } from '../components';
import { useTrackCajaEvent } from '../events';
import { useFormValidator } from '../hooks/useFormValidator';
import { findFirstPreviousInvalidStep } from '../stepValidation/findFirstPreviousInvalidStep';
import { getSteps } from './getSteps';
import { useBusinessState, useJobAndBusinessLimits } from './hooks';
import { getSteps as getPostJobSteps } from './steps/postJob/getSteps';
import { PostJob as MultiStepJobPost } from './steps/postJob/postJob';
import { PostJobSuccess } from './steps/postJobSuccess';
import { UpgradeJob } from './steps/upgradeJob';

export function PostJob() {
  const signUpLink = useGetSignUpLink();
  useRedirectUnauthenticatedUser({ to: signUpLink });
  useUpdateSourceQuery();

  const { showJobLimitReached, showOverBusinessLimitBlockingModal } =
    useJobAndBusinessLimits();

  const [createdJobId, setJobId] = useState<string | null>();
  const [businessCountryCode, setCountryCode] = useBusinessState();

  const { isUpgradeable } = useUpgradePermissions(businessCountryCode!!);

  const {
    steps,
    currentStep,
    currentStepIndex,
    gotoNextStep,
    stepsToStepIds,
    gotoSpecificStep
  } = useSteps<any>({
    data: {},
    getSteps: (data) => getSteps({ ...data, isUpgradeable })
  });

  const history = useHistory();

  // BAD hack for when the user clicks 'submit' on the job description step
  // Then go to 'upgradeJob' step and click the browser back button
  // They think they are adding a description to the job they just created, but they aren't,
  // A new draft is created, then they submit that and it borks the submit (as title etc are required)
  if (currentStep.id === 'jobDescription' && createdJobId) {
    const urlFirstPart = history.location.pathname.split('/')[1];
    history.push(
      `/${urlFirstPart}/jobs/${createdJobId}/edit-job/review-job-description`
    );
  }

  useTrackCajaEvent({
    step: currentStep.id,
    variant: formVariant.formVariant,
    jobId: createdJobId || undefined
  });

  const formValidator = useFormValidator();

  async function arePreviousStepsValid(data: Partial<PostJobFormData>) {
    const firstInvalidStep = await findFirstPreviousInvalidStep({
      allSteps: steps,
      currentStepIndex,
      data,
      validator: formValidator
    });
    if (firstInvalidStep) gotoSpecificStep(firstInvalidStep);
  }

  const onPostJobLoad = (data: Partial<PostJobFormData>) => {
    const dataCountryCode = data.business?.geolocation.countryCode;
    setCountryCode(dataCountryCode);
    arePreviousStepsValid(data);
  };

  const onPostJobNextStep = (data: Partial<PostJobFormData>) => {
    const dataCountryCode = data.business?.geolocation.countryCode;
    setCountryCode(dataCountryCode);
  };

  const onPostJobCompleted = (data: Partial<PostJobFormData>, { jobId }) => {
    setJobId(jobId);
    gotoNextStep(data);
  };

  const postJobSteps = getPostJobSteps({});

  if (showJobLimitReached) {
    return <JobLimitReached pageTitle={currentStep.pageTitle} />;
  }

  return (
    <CajaWrapper
      title={currentStep.pageTitle}
      step={{
        index: currentStepIndex,
        title: currentStep.title,
        total: steps.length
      }}
    >
      {showOverBusinessLimitBlockingModal && <UpdateYourBusinessesModal />}

      <Box padding="small">
        {stepsToStepIds(postJobSteps).includes(currentStep.id) && (
          <MultiStepJobPost
            onNext={onPostJobNextStep}
            onCompleted={onPostJobCompleted}
            onLoad={onPostJobLoad}
          />
        )}
        {currentStep.id === 'upgradeJob' && createdJobId && (
          <UpgradeJob
            jobId={createdJobId!!}
            jobCountryCode={businessCountryCode!!}
          />
        )}
        {currentStep.id === 'postJobSuccess' && createdJobId && (
          <PostJobSuccess jobId={createdJobId!!} />
        )}
      </Box>
    </CajaWrapper>
  );
}
