import React from 'react';
import { Redirect } from '@common/components/Redirect';
import { useGetJobPostedSuccessLink } from '@common/hooks/appPageLinks';

type Props = {
  jobId: string;
};

export function PostJobSuccess({ jobId }: Props) {
  const postJobSuccessLink = useGetJobPostedSuccessLink(jobId);

  return <Redirect to={postJobSuccessLink} />;
}
