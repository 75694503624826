import React from 'react';
import { ClientError } from '@common/helpers/errors';
import { Step, useSteps } from '@common/hooks/multiStep';
import { MultiStepPostJobContext } from './MultiStepPostJobContext';
import { hydrateDefaultFormOptions } from './hydrateDefaultFormOptions';
import {
  AdditionalInfoStep,
  ExperienceAndSalaryStep,
  JobTypeAndShiftStep,
  ReviewAndDescriptionStep,
  TitleAndBusinessStep
} from './steps';
import { FormOptions, PostJobFormData } from './types';

type MultiStepPostJobProps = {
  jobData: PostJobFormData;
  isLoading: boolean;
  error?: ClientError;
  onNext: (data: PostJobFormData) => Promise<void> | void;
  onCompleted: (data: PostJobFormData) => void;
  formOptions?: FormOptions;
  getFormSteps: (data: PostJobFormData) => Step[];
};

export const MultiStepPostJob = ({
  isLoading,
  jobData,
  error,
  onNext,
  onCompleted,
  formOptions = {},
  getFormSteps
}: MultiStepPostJobProps) => {
  const hydratedformOptions = hydrateDefaultFormOptions(formOptions);

  const { currentStep, gotoNextStep, gotoPreviousStep, stepInfo } =
    useSteps<PostJobFormData>({
      getSteps: (data) => getFormSteps(data),
      onCompleted,
      data: jobData
    });

  const onNextStep = async (data: PostJobFormData): Promise<void> => {
    try {
      await onNext(data);
      await gotoNextStep(data);
    } catch (e) {}
  };

  const {
    jobTitle,
    business,
    workExperience,
    salary,
    jobType,
    shiftAvailability,
    rightToWork,
    immediateStart
  } = jobData;

  return (
    <MultiStepPostJobContext.Provider value={hydratedformOptions}>
      {currentStep.id === 'title' && (
        <TitleAndBusinessStep
          isLoading={isLoading}
          error={error}
          formData={{ jobTitle, business }}
          onSubmit={onNextStep}
          onBack={gotoPreviousStep}
          stepInfo={stepInfo}
        />
      )}
      {currentStep.id === 'experience' && (
        <ExperienceAndSalaryStep
          isLoading={isLoading}
          error={error}
          formData={{ workExperience, salary }}
          onSubmit={onNextStep}
          onBack={gotoPreviousStep}
          stepInfo={stepInfo}
        />
      )}
      {currentStep.id === 'jobType' && (
        <JobTypeAndShiftStep
          isLoading={isLoading}
          error={error}
          formData={{ jobType, shiftAvailability }}
          onSubmit={onNextStep}
          onBack={gotoPreviousStep}
          stepInfo={stepInfo}
        />
      )}
      {currentStep.id === 'additionalInfo' && (
        <AdditionalInfoStep
          isLoading={isLoading}
          error={error}
          formData={{ rightToWork, immediateStart }}
          onSubmit={onNextStep}
          onBack={gotoPreviousStep}
          stepInfo={stepInfo}
        />
      )}
      {currentStep.id === 'jobDescription' && (
        <ReviewAndDescriptionStep
          isLoading={isLoading}
          error={error}
          formData={jobData}
          onSubmit={onNextStep}
          onBack={gotoPreviousStep}
          stepInfo={stepInfo}
        />
      )}
    </MultiStepPostJobContext.Provider>
  );
};
