import { Step } from '@common/hooks/multiStep/types';
import { PostJobFormData } from '../../../../Job/forms/MultiStepPostJob/types';

export const getSteps = (_data: PostJobFormData): Step[] => {
  return [
    {
      id: 'title',
      path: '/job-title-business-selection',
      title: 'postJobTitleStep.stepTitle',
      pageTitle: 'postJobTitleStep.heading'
    },
    {
      id: 'experience',
      path: '/work-experience-salary',
      title: 'postJobExperienceStep.stepTitle',
      pageTitle: 'postJobExperienceStep.heading'
    },
    {
      id: 'jobType',
      path: '/job-type-shift-availability',
      title: 'postJobTypeStep.stepTitle',
      pageTitle: 'postJobTypeStep.heading'
    },
    {
      id: 'additionalInfo',
      path: '/right-to-work-start-date',
      title: 'postJobAdditionalInfoStep.stepTitle',
      pageTitle: 'postJobAdditionalInfoStep.heading'
    },
    {
      id: 'jobDescription',
      path: '/review-job-description',
      title: 'postJobDescriptionStep.stepTitle',
      pageTitle: 'postJobDescriptionStep.heading'
    }
  ];
};
