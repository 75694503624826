import React, { useEffect, useRef } from 'react';
import {
  MultiStepPostJob,
  PostJobFormData
} from '@common/components/Job/forms/MultiStepPostJob';
import {
  trackMaxLengthValidationError,
  trackMinLengthValidationError
} from '@common/components/Job/forms/fields/JobDescription/jobDescriptionActions';
import {
  JOB_DESCRIPTION_MAX_LENGTH,
  JOB_DESCRIPTION_MULTISTEP_MIN_LENGTH
} from '@common/constants/validation';
import { useLoadBusinesses } from '@common/hooks/business';
import { useGetSourceParams } from '@common/hooks/useSearchQueryParam';
import { useAppDispatch } from '@store/hooks';
import { getSteps } from './getSteps';
import {
  useLoadRecentDraft,
  useMapDraftToFormData,
  useSaveDraft,
  useSaveJob
} from './hooks';

export interface InvalidDescriptionData {
  amount: number;
  requiredAmount: number;
  reason: 'MAX_LENGTH' | 'MIN_LENGTH';
}

type Props = {
  onNext: (stepData: PostJobFormData) => void;
  onCompleted: (stepData: Partial<PostJobFormData>, { jobId }) => void;
  onLoad: (stepData: Partial<PostJobFormData>) => void;
  repostJobId: string;
  isFirstStep: boolean;
};

export const RepostJob = ({
  onNext,
  onCompleted,
  onLoad,
  repostJobId,
  isFirstStep
}: Props) => {
  const dispatch = useAppDispatch();
  const sourceParams = useGetSourceParams();
  const { businesses, isLoading: businessesLoading } = useLoadBusinesses();
  const { saveJob, error: saveJobError } = useSaveJob();
  const { saveDraft, error: saveDraftError } = useSaveDraft(repostJobId);

  const { data: savedDraft, isLoading: draftLoading } = useLoadRecentDraft(
    repostJobId,
    { isFirstStep }
  );

  const draftJob = useMapDraftToFormData(savedDraft, businesses);

  const error = saveJobError || saveDraftError;
  const isLoading = businessesLoading || draftLoading;
  const onLoadRef = useRef(onLoad);

  useEffect(() => {
    if (draftJob && !isLoading) onLoadRef.current(draftJob);
  }, [draftJob, isLoading]);

  const onNextStep = async (data: PostJobFormData) => {
    onNext(data);
    await saveDraft(data);
  };

  const onPostJobCompleted = async (data: PostJobFormData) => {
    const { id: jobId } = await saveJob(repostJobId, sourceParams);
    onCompleted(data, { jobId });
  };

  const handleInvalidDescription = (data: InvalidDescriptionData) => {
    if (data.reason === 'MIN_LENGTH') {
      dispatch(trackMinLengthValidationError());
    } else if (data.reason === 'MAX_LENGTH') {
      dispatch(trackMaxLengthValidationError());
    }
  };

  if (!draftJob) return null;

  return (
    <MultiStepPostJob
      getFormSteps={getSteps}
      onCompleted={onPostJobCompleted}
      isLoading={isLoading}
      jobData={draftJob}
      error={error}
      onNext={onNextStep}
      formOptions={{
        jobDescriptionLength: {
          min: JOB_DESCRIPTION_MULTISTEP_MIN_LENGTH,
          max: JOB_DESCRIPTION_MAX_LENGTH
        },
        onInvalidDescription: handleInvalidDescription
      }}
    />
  );
};
