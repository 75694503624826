import { useState } from 'react';
import { ApplicationFilterValues, FILTERS, SORT } from '../../../types';

// separate type for future flexibility; form state can be different from raw filter values
// for now, they are identical
export type FilterForm = ApplicationFilterValues;

export type FilterValuesKey = keyof FilterForm;
export type FilterValuesProperty = FilterForm[FilterValuesKey];

export const DEFAULT_FILTER_STATE: FilterForm = {
  [FILTERS.SORT_APPLICATIONS]: SORT.APPLICATION_DATE
};

export const useFilterForm = () => {
  const [filterForm, setFilterForm] =
    useState<FilterForm>(DEFAULT_FILTER_STATE);

  const { handleFieldClear } = useHandleFieldClear(filterForm, setFilterForm);
  const { handleFieldChange } = useHandleFieldChange(filterForm, setFilterForm);

  return {
    filterForm,
    setFilterForm,
    handleFieldChange,
    handleFieldClear
  };
};

const useHandleFieldClear = (
  filterValues: ApplicationFilterValues,
  setFilterValues: (values: ApplicationFilterValues) => void
) => {
  const handleFieldClear = (key: FILTERS) => {
    return () => {
      const filters = { ...filterValues };
      delete filters[key];
      return setFilterValues(filters);
    };
  };

  return {
    handleFieldClear
  };
};

const useHandleFieldChange = (
  filterValues: ApplicationFilterValues,
  setFilterValues: (values: ApplicationFilterValues) => void
) => {
  const handleFieldChange = (key: FilterValuesKey) => {
    return (value: FilterValuesProperty) => {
      return setFilterValues({
        ...filterValues,
        [key]: value
      });
    };
  };

  return {
    handleFieldChange
  };
};
