import { PostJobFormData } from '@common/components/Job/forms/MultiStepPostJob';
import { DraftJob } from '@common/hooks/job/useFindRecentDraft';
import { BusinessWithUsers } from '@store/entities/businesses/types';
import { toPostJobFormData } from '../transformers/draft/toPostJobFormData';
import { useDraftBusiness } from './useDraftBusiness';

export const useMapDraftToFormData = (
  draft: DraftJob,
  businesses: BusinessWithUsers[]
): PostJobFormData => {
  const business = useDraftBusiness(businesses, draft?.businessId);

  return toPostJobFormData(draft, business);
};
