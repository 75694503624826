import React from 'react';
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';
import { useTranslation } from '@common/hooks/useTranslation';
import { jobTypeRules } from '../../rules/jobType';
import { JobTypeRadioField } from './JobTypeRadioField';

export default function JobType<T extends FieldValues>({
  name,
  control
}: UseControllerProps<T>) {
  const { t } = useTranslation();

  return (
    <Controller
      name={name}
      control={control}
      rules={jobTypeRules(t)}
      render={({ field: { onChange, value }, formState }) => (
        <JobTypeRadioField
          onChange={onChange}
          value={value}
          error={formState?.errors[name]?.message as string}
        />
      )}
    />
  );
}
