import React, { forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Else, If, Then } from '@common/components/If';
import { When } from '@common/components/When';
import {
  useAccountUpgradeable,
  usePremiumApplicantFiltersFeature
} from '@common/hooks/featureToggles';
import { useUserHasPremiumFilterAccess } from '@common/hooks/userPackages';
import { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';
import { getApplications } from '@store/entities/applications';
import { getJobById } from '@store/entities/jobs';
import { useAppSelector } from '@store/hooks';
import { Divider } from '@components/Divider';
import { Modal } from '@components/Modal';
import { Tabs } from '@components/Tabs';
import { FILTERS } from '../../../../../../types';
import { useFilterFormContext } from '../../../../FilterFormContext';
import { useTrackFiltersCleared } from '../../../../hooks';
import { PremiumFeaturesBadge } from '../../../PremiumFeaturesBadge';
import { FooterActions } from '../FooterActions';
import {
  ApplicantStatusFilter,
  CitizenshipFilter,
  DisabledCitizenshipFilter,
  DisabledDistanceFilter,
  DisabledEducationFilter,
  DisabledLocationFilter,
  DisabledRightToWorkFilter,
  DistanceFilter,
  EducationFilter,
  LocationFilter,
  RightToWorkFilter,
  SortApplications,
  UpgradeAccountLink,
  VideoScreeningFilter
} from './components';
import {
  DisabledWorkExperienceFilter,
  WorkExperienceFilter
} from './components/WorkExperienceFilter';
import {
  useHandleApplyButton,
  useHandleClearButton,
  useHandleUpgradeLinkClick
} from './hooks';

type FiltersContentProps = {
  toggleModalState: () => void;
  onRequestClose: () => void;
  visible: boolean;
};
export const FiltersContent = forwardRef(
  ({ toggleModalState, onRequestClose, visible }: FiltersContentProps, ref) => {
    const { t } = useTranslation();

    const { jobId } = useParams<{ jobId: string }>();
    const job = useAppSelector(getJobById(jobId)) || {};
    const { data: allApplications } =
      useAppSelector(getApplications(jobId)) || {};

    const accountUpgradeable = useAccountUpgradeable();
    const showPremiumFilters = usePremiumApplicantFiltersFeature();
    const hasPremiumFilterAccess = useUserHasPremiumFilterAccess();

    const {
      filterForm,
      applicationFilters,
      handleFieldChange,
      handleFieldClear,
      applySelectedFilters,
      setFilterForm,
      clearAllFilters
    } = useFilterFormContext();

    const onApplyButtonClicked = useHandleApplyButton({
      filterForm,
      applySelectedFilters,
      closeModal: onRequestClose
    });
    const onClearAllButtonClicked = useHandleClearButton({
      clearAllFilters,
      closeModal: onRequestClose
    });
    const sendClearAllFiltersTrackingEvent = useTrackFiltersCleared();
    const onUpgradeLink = useHandleUpgradeLinkClick(toggleModalState);

    useImperativeHandle(ref, () => ({
      clearAllFilters,
      sendClearAllFiltersTrackingEvent
    }));

    const handleRequestClose = () => {
      setFilterForm(applicationFilters);
      onRequestClose();
    };

    return (
      <Modal
        visible={visible}
        title={t('applications.applicationsFilter.action.filter')}
        height="fit-content"
        testID="applications-filter-modal"
        onRequestClose={handleRequestClose}
        footerChildren={
          <FooterActions
            clearAllFilters={onClearAllButtonClicked}
            applyFilters={onApplyButtonClicked}
          />
        }
      >
        <div className="flex flex-col space-y-lg">
          {accountUpgradeable ? (
            <UpgradeAccountLink onPress={onUpgradeLink} />
          ) : null}

          <SortApplications
            value={filterForm[FILTERS.SORT_APPLICATIONS]}
            onChange={handleFieldChange(FILTERS.SORT_APPLICATIONS)}
          />

          <Divider />

          <ApplicantStatusFilter
            applications={allApplications}
            value={filterForm[FILTERS.APPLICANT_STATUS]}
            onChange={handleFieldChange(FILTERS.APPLICANT_STATUS)}
            onClear={handleFieldClear(FILTERS.APPLICANT_STATUS)}
          />

          <Divider />

          <VideoScreeningFilter
            applications={allApplications}
            value={filterForm[FILTERS.VIDEO_SCREENING]}
            onChange={handleFieldChange(FILTERS.VIDEO_SCREENING)}
            onClear={handleFieldClear(FILTERS.VIDEO_SCREENING)}
          />

          <When condition={showPremiumFilters}>
            <div className="flex flex-col space-y-lg">
              <Divider />

              <If condition={hasPremiumFilterAccess}>
                <Then>
                  <WorkExperienceFilter
                    applications={allApplications}
                    value={filterForm[FILTERS.WORK_EXPERIENCE]}
                    onChange={handleFieldChange(FILTERS.WORK_EXPERIENCE)}
                    onClear={handleFieldClear(FILTERS.WORK_EXPERIENCE)}
                  />
                </Then>
                <Else>
                  <DisabledWorkExperienceFilter
                    applications={allApplications}
                  />
                </Else>
              </If>

              <Divider />

              <Tabs
                onChange={(key) => {
                  key === 'distance'
                    ? handleFieldClear(FILTERS.LOCATION)()
                    : handleFieldClear(FILTERS.DISTANCE)();
                }}
                suffix={
                  <If condition={hasPremiumFilterAccess}>
                    <Then>
                      <div className="text-sm text-gray-600 whitespace-nowrap">
                        {t('applications.applicationsFilter.selectOneFilter')}
                      </div>
                    </Then>
                    <Else>
                      <div className="min-w-min mb-5 md:mb-0">
                        <PremiumFeaturesBadge />
                      </div>
                    </Else>
                  </If>
                }
                items={[
                  {
                    label: t('applications.applicationsFilter.distance.title'),
                    key: 'distance',
                    content: (
                      <If condition={hasPremiumFilterAccess}>
                        <Then>
                          <DistanceFilter
                            value={filterForm[FILTERS.DISTANCE]}
                            onChange={handleFieldChange(FILTERS.DISTANCE)}
                            onClear={handleFieldClear(FILTERS.DISTANCE)}
                          />
                        </Then>
                        <Else>
                          <DisabledDistanceFilter />
                        </Else>
                      </If>
                    )
                  },
                  {
                    label: t('applications.applicationsFilter.location.title'),
                    key: 'location',
                    content: (
                      <If condition={hasPremiumFilterAccess}>
                        <Then>
                          <LocationFilter
                            value={filterForm[FILTERS.LOCATION]}
                            onChange={handleFieldChange(FILTERS.LOCATION)}
                            onClear={handleFieldClear(FILTERS.LOCATION)}
                            profileApplications={allApplications}
                            primaryCountry={
                              job?.countryCode as SupportedCountryCode
                            }
                          />
                        </Then>
                        <Else>
                          <DisabledLocationFilter
                            profileApplications={allApplications}
                          />
                        </Else>
                      </If>
                    )
                  }
                ]}
              />

              <Divider />

              <If condition={hasPremiumFilterAccess}>
                <Then>
                  <RightToWorkFilter
                    applications={allApplications}
                    value={filterForm[FILTERS.RIGHT_TO_WORK]}
                    onChange={handleFieldChange(FILTERS.RIGHT_TO_WORK)}
                    onClear={handleFieldClear(FILTERS.RIGHT_TO_WORK)}
                  />
                </Then>
                <Else>
                  <DisabledRightToWorkFilter applications={allApplications} />
                </Else>
              </If>

              <Divider />

              <If condition={hasPremiumFilterAccess}>
                <Then>
                  <EducationFilter
                    applications={allApplications}
                    value={filterForm[FILTERS.EDUCATION]}
                    onChange={handleFieldChange(FILTERS.EDUCATION)}
                    onClear={handleFieldClear(FILTERS.EDUCATION)}
                  />
                </Then>
                <Else>
                  <DisabledEducationFilter applications={allApplications} />
                </Else>
              </If>

              <Divider />

              <If condition={hasPremiumFilterAccess}>
                <Then>
                  <CitizenshipFilter
                    value={filterForm[FILTERS.CITIZENSHIP]}
                    onChange={handleFieldChange(FILTERS.CITIZENSHIP)}
                    onClear={handleFieldClear(FILTERS.CITIZENSHIP)}
                    applications={allApplications}
                  />
                </Then>
                <Else>
                  <DisabledCitizenshipFilter applications={allApplications} />
                </Else>
              </If>
            </div>
          </When>
        </div>
      </Modal>
    );
  }
);
