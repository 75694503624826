import { FormOptions } from './types';

export const hydrateDefaultFormOptions = (formOptions: FormOptions) => {
  return {
    redirectCreateBusinessTo: formOptions.redirectCreateBusinessTo ?? 'PostJob',
    isBusinessEditable: formOptions.isBusinessEditable ?? true,
    jobDescriptionLength: formOptions.jobDescriptionLength ?? {
      min: 1,
      max: 100
    },
    onInvalidDescription: formOptions.onInvalidDescription ?? (() => {})
  };
};
