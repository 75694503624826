import { EditJob } from '@common/components/Caja/editJob';
import { PostJob } from '@common/components/Caja/postJob';
import { RepostJob } from '@common/components/Caja/repostJob';
import { SignUp } from '@common/components/Caja/signUp';
import { HTTP_NOT_FOUND } from '../common/constants/httpResponse';
import { Shell } from './components/JoraEmployerShell';
import { Account } from './pages/account/Account';
import { DeleteAccountSuccess } from './pages/account/delete-account-success/DeleteAccountSuccess';
import { DeleteAccount } from './pages/account/delete-account/DeleteAccount';
import { UpdateAccountDetails } from './pages/account/update-account-details/UpdateAccountDetails';
import UpdatePassword from './pages/account/update-password/UpdatePassword';
import { VerifyAccountDetailsSuccess } from './pages/account/verify-account-details-success/VerifyAccountDetailsSuccess';
import { VerifyAccountDetails } from './pages/account/verify-account-details/VerifyAccountDetails';
import { VerifyEmailSuccess } from './pages/account/verify-email-success/VerifyEmailSuccess';
import { VerifyEmail } from './pages/account/verify-email/VerifyEmail';
import { ForgotPasswordSuccess } from './pages/auth/forgot-password-success/ForgotPasswordSuccess';
import ForgotPassword from './pages/auth/forgot-password/ForgotPassword';
import ImpersonatorAuthenticate from './pages/auth/impersonator-authenticate/ImpersonatorAuthenticate';
import { Login } from './pages/auth/login/Login';
import { ResetPasswordSuccess } from './pages/auth/reset-password-success/ResetPasswordSuccess';
import ResetPassword from './pages/auth/reset-password/ResetPassword';
import CreateBusiness from './pages/business/create/CreateBusiness';
import { BusinessDetails } from './pages/business/details';
import EditBusiness from './pages/business/edit/EditBusiness';
import { ListBusinesses } from './pages/business/list-businesses/ListBusinesses';
import Dashboard from './pages/dashboard/Dashboard';
import { DeleteJobSuccess } from './pages/job/delete-job-success/DeleteJobSuccess';
import { JobDetailsAndApplicationsList } from './pages/job/details';
import { EditJobSuccess } from './pages/job/edit-job-success/EditJobSuccess';
import { JobNotFound } from './pages/job/job-not-found/JobNotFound';
import { PaymentSuccess } from './pages/job/payment-success/PaymentSuccess';
import { Payment } from './pages/job/payment/Payment';
import { PostJobSuccess } from './pages/job/post-job-success/PostJobSuccess';
import { UpgradeJob } from './pages/job/upgrade-job/UpgradeJob';
import { Landing } from './pages/landing/Landing';
import { MyCoins } from './pages/myCoins/MyCoins';
import { NotFound } from './pages/notFound/NotFound';
import RedirectFromThirdParty from './pages/redirectFromThirdParty/RedirectFromThirdParty';
import { RegionSelection } from './pages/regionSelection/RegionSelection';
import Unsubscribe from './pages/unsubscribe/Unsubscribe';
import { commonRoutePaths } from './routeConstants';

export default {
  path: '/',
  component: Shell,
  childRoutes: [
    ...commonRoutePaths,
    { path: ':countryCode/sign-up', component: SignUp, exact: false },
    { path: ':countryCode/region-selection', component: RegionSelection },
    {
      path: ':countryCode/post-job',
      component: PostJob,
      exact: false
    },
    {
      path: ':countryCode/jobs/:originalJobId/repost-job',
      component: RepostJob,
      exact: false
    },
    {
      path: ':countryCode/jobs/:jobId/edit-job',
      component: EditJob,
      exact: false
    },
    {
      path: ':countryCode/jobs/:jobId/details',
      component: JobDetailsAndApplicationsList
    },
    { path: ':countryCode/jobs/:jobId/upgrade-job', component: UpgradeJob },
    {
      path: ':countryCode/jobs/:jobId/post-job-success',
      component: PostJobSuccess
    },
    {
      path: ':countryCode/jobs/:jobId/edit-job-success',
      component: EditJobSuccess
    },
    {
      path: ':countryCode/jobs/:jobId/close-job-success',
      component: DeleteJobSuccess
    },
    { path: ':countryCode/jobs/:jobId/payment', component: Payment },
    {
      path: ':countryCode/jobs/:jobId/payment-success',
      component: PaymentSuccess
    },
    {
      path: ':countryCode/jobs/job-not-found',
      component: JobNotFound
    },
    { path: ':countryCode/businesses', component: ListBusinesses },
    { path: ':countryCode/businesses/create', component: CreateBusiness },
    {
      path: ':countryCode/businesses/:businessId/edit-business',
      component: EditBusiness
    },
    {
      path: ':countryCode/businesses/:businessId/details',
      component: BusinessDetails
    },
    { path: ':countryCode/forgot-password', component: ForgotPassword },
    {
      path: ':countryCode/forgot-password-success',
      component: ForgotPasswordSuccess
    },
    { path: ':countryCode/reset-password', component: ResetPassword },
    {
      path: ':countryCode/reset-password-success',
      component: ResetPasswordSuccess
    },
    { path: ':countryCode/account', component: Account },
    { path: ':countryCode/account/update-password', component: UpdatePassword },
    {
      path: ':countryCode/account/update-details',
      component: UpdateAccountDetails
    },
    { path: ':countryCode/account/delete-account', component: DeleteAccount },
    {
      path: ':countryCode/account/delete-account-success',
      component: DeleteAccountSuccess
    },
    {
      path: ':countryCode/account/verify-account-details',
      component: VerifyAccountDetails
    },
    {
      path: ':countryCode/account/verify-account-details-success',
      component: VerifyAccountDetailsSuccess
    },
    { path: ':countryCode/account/verify-email', component: VerifyEmail },
    {
      path: ':countryCode/account/verify-email-success',
      component: VerifyEmailSuccess
    },
    { path: ':countryCode/auth', component: ImpersonatorAuthenticate },
    { path: ':countryCode/login', component: Login },
    { path: ':countryCode/home', component: Dashboard },
    { path: ':countryCode/redirect', component: RedirectFromThirdParty },
    { path: ':countryCode/', component: Landing },
    { path: ':countryCode/unsubscribe', component: Unsubscribe },
    { path: ':countryCode/my-coins', component: MyCoins },
    { path: '*', component: NotFound, status: HTTP_NOT_FOUND }
  ]
};
