import { useAppDispatch } from '@store/hooks';
import { set } from '@store/userData/actions';

export function useUpdateRecentlyUsedBusiness() {
  const dispatch = useAppDispatch();

  return (businessId?: string) => {
    if (businessId) {
      dispatch(set('recentlyUsedBusiness', { id: businessId }));
    }
  };
}
