import { useTranslation } from '@common/hooks/useTranslation';
import {
  SHIFT_AVAILABILITY_TYPE,
  ShiftAvailabilityType,
  WorkExperience
} from '@seek/je-shared-data/lib/types/jobs';
import { PostJobFormData } from '../../../types';
import { getSalarySummary } from './getSalarySummary';
import { getShiftAvailabilitySummary } from './getShiftAvailabilitySummary';
import { getWorkExperienceSummary } from './getWorkExpereinceSummary';

const isShiftTypeAnyTime = (
  shiftAvailabilityType: ShiftAvailabilityType | undefined
) => shiftAvailabilityType === SHIFT_AVAILABILITY_TYPE.ANY_TIME;

const isShiftTypeSpecific = (
  shiftAvailabilityType: ShiftAvailabilityType | undefined
) => shiftAvailabilityType === SHIFT_AVAILABILITY_TYPE.SPECIFIC;

export default function useJobSummary(job: PostJobFormData) {
  const { t } = useTranslation();

  return {
    isSalaryDefined: !!job.salary?.amount,
    salary: getSalarySummary(job.salary, t),
    workExperience: getWorkExperienceSummary(
      job.workExperience as WorkExperience,
      t
    ),
    isShiftTypeAnyTime: isShiftTypeAnyTime(job.shiftAvailability?.type),
    isShiftTypeSpecific: isShiftTypeSpecific(job.shiftAvailability?.type),
    shiftAvailability: getShiftAvailabilitySummary(job.shiftAvailability, t)
  };
}
