import React from 'react';
import { useForm } from 'react-hook-form';
import { ExpectedSalary, WorkExperience } from '../../../fields';
import { NavControls } from '../../components';
import { PostJobFormData } from '../../types';
import { ExperienceAndSalaryFormData } from './types';

type ExperienceAndSalaryFormProps = {
  defaultValues: ExperienceAndSalaryFormData;
  stepInfo: {
    showBackButton: boolean;
    backText: string;
    submitText: string;
  };
  onSubmit: (data: PostJobFormData) => Promise<void>;
  onBack: () => void;
};

export const ExperienceAndSalaryForm = ({
  defaultValues,
  stepInfo,
  onSubmit,
  onBack
}: ExperienceAndSalaryFormProps) => {
  const { handleSubmit, control, formState } =
    useForm<ExperienceAndSalaryFormData>({
      defaultValues
    });

  const handleFormSubmitForStep = async (data: ExperienceAndSalaryFormData) => {
    await onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmitForStep)}>
      <div className="space-y-12">
        <WorkExperience name="workExperience" control={control} />
        <ExpectedSalary name="salary" control={control} />

        <NavControls
          onBack={onBack}
          onSubmit={handleSubmit(handleFormSubmitForStep)}
          formState={formState}
          {...stepInfo}
        />
      </div>
    </form>
  );
};
