import { Response as GetJobForEditResponse } from 'je-bff/src/jobs/get/edit/get';
import { ClientError } from '@common/helpers/errors';
import { trpcBff } from '@trpcBff';

export type Params = {
  jobId: string;
};

export type Data = GetJobForEditResponse | undefined;
export type Return = {
  data: Data;
  isLoading: boolean;
  error?: ClientError;
};

export const useGetJobForEdit = ({ jobId }: Params): Return => {
  const { data, isLoading, error } = trpcBff.jobs.get.jobForEdit.useQuery(
    { jobId },
    {
      staleTime: 10000,
      retry: false
    }
  );

  return {
    data,
    isLoading,
    error: getClientError(error)
  };
};

const getClientError = (errorProperties) => {
  if (!errorProperties) return;

  return new ClientError('errors.defaultWithGuid', {
    guid: errorProperties.guid,
    responseBody: errorProperties.responseBody
  });
};
