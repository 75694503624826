import React from 'react';
import { Skeleton } from 'antd';
import { ClientError } from '@common/helpers/errors';
import { StepError, StepWrapper } from '../../components';
import { ImmediateStartAndRightToWorkForm } from './ImmediateStartAndRightToWorkForm';
import { ImmediateStartAndRightToWorkFormData } from './types';

type AdditionalInfoStepProps = {
  isLoading: boolean;
  error?: ClientError;
  formData: ImmediateStartAndRightToWorkFormData;
  stepInfo: {
    showBackButton: boolean;
    backText: string;
    submitText: string;
  };
  onSubmit: (data: ImmediateStartAndRightToWorkFormData) => Promise<void>;
  onBack: () => void;
};

export const AdditionalInfoStep = ({
  isLoading,
  error,
  formData,
  onSubmit,
  onBack,
  stepInfo
}: AdditionalInfoStepProps) => {
  if (isLoading) {
    return (
      <StepWrapper>
        <Skeleton active />
      </StepWrapper>
    );
  }

  return (
    <StepWrapper>
      <ImmediateStartAndRightToWorkForm
        defaultValues={formData}
        onSubmit={onSubmit}
        onBack={onBack}
        stepInfo={stepInfo}
      />

      <StepError error={error} />
    </StepWrapper>
  );
};
