import { Step } from '@common/hooks/multiStep/types';
import { getSteps as getPostJobSteps } from './steps/postJob/getSteps';

export const getSteps = ({
  isUpgradeable
}: {
  isUpgradeable: boolean;
}): Step[] => {
  const steps = {
    createAccount: {
      id: 'createAccount',
      path: '/account/create',
      title: 'createAccountStep.stepTitle',
      pageTitle: 'createAccountStep.heading'
    },
    verifyEmail: {
      id: 'verifyEmail',
      path: '/account/verify-email',
      title: 'verifyEmailStep.stepTitle',
      pageTitle: 'verifyEmailStep.heading'
    },
    createBusiness: {
      id: 'createBusiness',
      path: '/business/create',
      title: 'createBusinessStep.stepTitle',
      pageTitle: 'createBusinessStep.heading'
    },
    upgradeJob: {
      id: 'upgradeJob',
      path: '/upgrade-job',
      title: 'upgradeJobStep.stepTitle',
      pageTitle: 'upgradeJobStep.heading'
    },
    postJobSuccess: {
      id: 'postJobSuccess',
      path: '/post-job-success',
      title: '',
      pageTitle: ''
    }
  };

  const {
    createAccount,
    verifyEmail,
    createBusiness,
    upgradeJob,
    postJobSuccess
  } = steps;

  const postJobSteps = getPostJobSteps({});

  if (isUpgradeable) {
    return [
      createAccount,
      verifyEmail,
      createBusiness,
      ...postJobSteps,
      upgradeJob
    ];
  }

  return [
    createAccount,
    verifyEmail,
    createBusiness,
    ...postJobSteps,
    postJobSuccess
  ];
};
