import React from 'react';
import { Spinner } from '@common/components/Spinner';
import { ClientError } from '@common/helpers/errors';
import { StepError, StepWrapper } from '../../components';
import { PostJobFormData } from '../../types';
import { TitleAndBusinessForm } from './TitleAndBusinessForm';
import { TitleAndBusinessFormData } from './types';

type TitleAndBusinessStepProps = {
  isLoading: boolean;
  error?: ClientError;
  formData: TitleAndBusinessFormData;
  stepInfo: {
    showBackButton: boolean;
    backText: string;
    submitText: string;
  };
  onSubmit: (data: PostJobFormData) => Promise<void>;
  onBack: () => void;
};

export const TitleAndBusinessStep = ({
  isLoading,
  error,
  formData,
  onSubmit,
  onBack,
  stepInfo
}: TitleAndBusinessStepProps) => {
  if (isLoading) {
    return (
      <StepWrapper>
        <Spinner size="50" />
      </StepWrapper>
    );
  }

  return (
    <StepWrapper>
      <TitleAndBusinessForm
        defaultValues={formData}
        onSubmit={onSubmit}
        onBack={onBack}
        stepInfo={stepInfo}
      />

      <StepError error={error} />
    </StepWrapper>
  );
};
