import { useFindRecentDraft } from '@common/hooks/job/useFindRecentDraft';
import { useLoadJobForRepost } from '@common/hooks/job/useLoadJobForRepost';

export function useLoadRecentDraft(
  jobId: string,
  { isFirstStep }: { isFirstStep: boolean }
) {
  const {
    data: savedDraft,
    isLoading: draftLoading,
    error: findRecentDraftError
  } = useFindRecentDraft({
    repostJobId: jobId,
    queryOpts: {
      enabled: !isFirstStep
    }
  });

  const {
    data: initialSavedDraft,
    isLoading: initialDraftLoading,
    error: initialDraftError
  } = useLoadJobForRepost({
    repostJobId: jobId,
    queryOpts: {
      enabled: isFirstStep
    }
  });

  return {
    data: initialSavedDraft || savedDraft,
    isLoading:
      (!isFirstStep && draftLoading) || (isFirstStep && initialDraftLoading),
    error: initialDraftError || findRecentDraftError
  };
}
