import {
  useMismatchedStripeAccount,
  useMonetisationEnabled
} from '@common/hooks/featureToggles/useMonetisationEnabled';
import { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';

export function useUpgradePermissions(jobCountryCode: SupportedCountryCode) {
  const isMismatchedStripeAccount = useMismatchedStripeAccount(jobCountryCode);
  const isMonetisationEnabled = useMonetisationEnabled(jobCountryCode);

  return {
    isUpgradeable:
      Boolean(isMismatchedStripeAccount) === false &&
      Boolean(isMonetisationEnabled)
  };
}
