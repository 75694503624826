import { PostJobFormData as EditJobFormData } from '@common/components/Job/forms/MultiStepPostJob';
import { UpdateJobParams } from '@common/hooks/job/useUpdateJob/useUpdateJob';
import { Salary } from '@seek/je-shared-data/lib/types/jobs';

type ShiftAvailabiltyParam = UpdateJobParams['shiftAvailability'];

export type CompleteEditJobFormData = Required<EditJobFormData> & {
  jobDescription?: string;
  salary?: Salary;
  jobTitle: { jobTitle: string; role: string };
};

export const toUpdateJobParams = (
  jobId: string,
  job: CompleteEditJobFormData
): UpdateJobParams => ({
  id: jobId,
  businessId: job.business?.id,
  title: job.jobTitle.jobTitle,
  jobRole: job.jobTitle.role,
  workExperience: job.workExperience,
  salary: job.salary ? job.salary : undefined,
  jobType: job.jobType,
  shiftAvailability: job.shiftAvailability as ShiftAvailabiltyParam,
  rightToWork: job.rightToWork,
  immediateStart: job.immediateStart,
  description: job.jobDescription
});
