import React from 'react';
import { useForm } from 'react-hook-form';
import { ImmediateStart, RightToWork } from '../../../fields';
import { NavControls } from '../../components';
import { ImmediateStartAndRightToWorkFormData } from './types';

type ImmediateStartAndRightToWorkFormProps = {
  defaultValues: ImmediateStartAndRightToWorkFormData;
  stepInfo: {
    showBackButton: boolean;
    backText: string;
    submitText: string;
  };
  onSubmit: (data: ImmediateStartAndRightToWorkFormData) => Promise<void>;
  onBack: () => void;
};

export const ImmediateStartAndRightToWorkForm = ({
  defaultValues,
  stepInfo,
  onSubmit,
  onBack
}: ImmediateStartAndRightToWorkFormProps) => {
  const { handleSubmit, control, formState } =
    useForm<ImmediateStartAndRightToWorkFormData>({
      defaultValues
    });

  const handleFormSubmitForStep = async (
    data: ImmediateStartAndRightToWorkFormData
  ) => {
    await onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmitForStep)}>
      <div className="space-y-12">
        <RightToWork name="rightToWork" control={control} />

        <ImmediateStart name="immediateStart" control={control} />

        <NavControls
          onBack={onBack}
          onSubmit={handleSubmit(handleFormSubmitForStep)}
          formState={formState}
          {...stepInfo}
        />
      </div>
    </form>
  );
};
