import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { JobDescription } from '../../../fields';
import { MultiStepPostJobContext } from '../../MultiStepPostJobContext';
import { NavControls } from '../../components';
import { DescriptionFormData } from './types';

type TitleAndBusinessFormProps = {
  defaultValues: DescriptionFormData;
  stepInfo: {
    showBackButton: boolean;
    backText: string;
    submitText: string;
  };
  onSubmit: (data: DescriptionFormData) => Promise<void>;
  onBack: () => void;
};

export const DescriptionForm = ({
  defaultValues,
  stepInfo,
  onSubmit,
  onBack
}: TitleAndBusinessFormProps) => {
  const { handleSubmit, control, formState } = useForm<DescriptionFormData>({
    defaultValues,
    mode: 'onBlur'
  });

  const { jobDescriptionLength, onInvalidDescription } = useContext(
    MultiStepPostJobContext
  );

  const handleFormSubmitForStep = async (data: DescriptionFormData) => {
    await onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmitForStep)}>
      <div className="space-y-12">
        <JobDescription
          name="jobDescription"
          control={control}
          jobDescriptionLength={jobDescriptionLength}
          onInvalidDescription={onInvalidDescription}
        />

        <NavControls
          onBack={onBack}
          onSubmit={handleSubmit(handleFormSubmitForStep)}
          formState={formState}
          {...stepInfo}
        />
      </div>
    </form>
  );
};
